import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCheckout, fetchPrices, setSearchCarParams } from 'actions/';
import { props } from './SearchItemRow.props';
import { Btn, CustomAlert, NumberDisplay, TranslatedComponent } from 'components/';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from 'utils/translationHelper';
import moment from 'moment';
import { getSippByCategory } from '../../../../utils/categoryGroupHelper';
import { is_gtag_enabled, gtag_product_click } from '../../../../utils/googleTagsHelper';
import { MODERN_THEME } from 'styled/constants';
import DefaultSearchItemRow from './Default';
import ModernSearchItemRow from './Modern';
import { getPriceWithCommission } from 'utils/dataHelper';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';

class SearchItemRow extends TranslatedComponent {
  static propTypes = props;

  constructor() {
    super();
    this.isOnlyQuote = this.isOnlyQuote.bind(this);
    this.getCarName = this.getCarName.bind(this);
    this.getCategoryOrGroupName = this.getCategoryOrGroupName.bind(this);
    this.hasLimitedAvailability = this.hasLimitedAvailability.bind(this);
    this.parsePartialAvailabilityAlert = this.parsePartialAvailabilityAlert.bind(this);
    this.checkoutLink = this.checkoutLink.bind(this);
    this.checkoutButton = this.checkoutButton.bind(this);
  }

  goToCheckout = async (onlinePayment, promotion, button) => {
    const {
      history,
      setCheckout,
      t,
      i18n,
      checkoutRoute,
      searchCars: { params },
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      setSearchCarParams,
      car,
    } = this.props;

    let isPartial = false;
    let isOnline = false;
    if (this.hasLimitedAvailability()) {
      params['hourFrom'] = this.getCarDateHour(car.fromDate);
      params['hourTo'] = this.getCarDateHour(car.toDate);
      setSearchCarParams(params);
    }

    if (is_gtag_enabled(googleTagManager)) gtag_product_click(car, settings);

    if (
      button.paymentMethod === 'partial' &&
      button.partialPaymentAmountIsPercentage &&
      button.partialPaymentAmount
    ) {
      isPartial = true;
    }

    if (button.paymentMethod === 'online') {
      isOnline = true;
    }

    setCheckout({
      booking: {
        ...this.props.car,
        promotion,
        onlyQuote: this.isOnlyQuote(car),
        isPartial: isPartial,
        isOnline: isOnline,
        partialPaymentAmount: button.partialPaymentAmount,
      },
      onlinePayment: onlinePayment,
    });

    history.push(tlink(checkoutRoute, t, i18n, null, settings.configurations.langConfig));
    window.scrollTo(0, 0);
  };

  sanatizeDetailText(text) {
    return text.length > 3 ? text.substring(0, 3) + '.' : text;
  }

  getPromotion(id) {
    const {
      car: { availablePromotions },
    } = this.props;
    return availablePromotions.find(x => x.id === id);
  }

  getCarDateHour(date) {
    return date.slice(11).replace(':00Z', '');
  }

  hasLimitedAvailability() {
    const { car } = this.props;
    const { params } = this.props.searchCars;

    const startParam = moment(params.hourFrom, 'hh:mm');
    const endParam = moment(params.hourTo, 'hh:mm');

    const startCar = moment(this.getCarDateHour(car.fromDate), 'hh:mm');
    const endCar = moment(this.getCarDateHour(car.toDate), 'hh:mm');

    if (startCar.isAfter(startParam) || endCar.isBefore(endParam)) return true;
    return false;
  }

  changePrice(obj, type) {
    const {
      settings: { paymentConfiguration },
      searchCars: {
        params: { pickUpEndpoint, customPromotionId },
      },
    } = this.props;

    function priceChanger(obj, promotion) {
      if (promotion)
        obj.Price = getPriceWithCommission(promotion.bookingFinalPrice, paymentConfiguration);

      obj.Promotion = promotion?.id;
    }

    // CREATE ENUM FOR PAYMENT TYPE
    const ValidPaymentTypeEnum = Object.freeze({
      Both: 0,
      Counter: 1,
      Online: 2,
    });

    let promotion = null;
    if (paymentConfiguration?.promotions && paymentConfiguration?.promotions[pickUpEndpoint]) {
      const promoId = obj.Value
        ? paymentConfiguration.promotions[pickUpEndpoint].onlinePromotion
        : paymentConfiguration.promotions[pickUpEndpoint].counterPromotion;
      promotion = this.getPromotion(promoId);
    }

    // EVALUATES IF THE PROMOTION IS VALID FOR THE APPROPRIATE PAYMENT METHOD
    const isValidForBoth = promotion && promotion.validForPayment === ValidPaymentTypeEnum.Both;
    const isValidForCounter =
      promotion && promotion.validForPayment === ValidPaymentTypeEnum.Counter;
    const isValidForOnline = promotion && promotion.validForPayment === ValidPaymentTypeEnum.Online;

    if (isValidForBoth) {
      priceChanger(obj, promotion);
    } else if (isValidForCounter && !obj.Value) {
      priceChanger(obj, promotion);
    } else if (isValidForOnline && obj.Value) {
      priceChanger(obj, promotion);
    }

    if (customPromotionId) {
      promotion = this.getPromotion(Number(customPromotionId));
    }

    //APPLIES CUSTOM PROMO IF APPLIES TO THE APPROPRIATE PAYMENT METHOD
    if (promotion) {
      const isValidForBoth = promotion.validForPayment === ValidPaymentTypeEnum.Both;
      const isValidForCounter = promotion.validForPayment === ValidPaymentTypeEnum.Counter;
      const isValidForOnline = promotion.validForPayment === ValidPaymentTypeEnum.Online;

      if (
        isValidForBoth ||
        (type === 'counter' && isValidForCounter) ||
        (type === 'online' && isValidForOnline)
      ) {
        priceChanger(obj, promotion);
      }
    }
  }

  parsePartialAvailabilityAlert(type, t, fromDate, toDate) {
    const { partialAvailabilityMessage } = this.props;
    let customMessage = null;
    if (partialAvailabilityMessage) customMessage = tt(partialAvailabilityMessage, t);

    return (
      <CustomAlert icon="far fa-clock" className={`partial-availability-${type}`}>
        {customMessage ? (
          <b>
            {customMessage
              .replace('[from]', this.getCarDateHour(fromDate))
              .replace('[to]', this.getCarDateHour(toDate))}
          </b>
        ) : (
          <b>
            {t('limitedAvailability', {
              from: this.getCarDateHour(fromDate),
              to: this.getCarDateHour(toDate),
            })}
          </b>
        )}
      </CustomAlert>
    );
  }

  getCarName(car) {
    const {
      t,
      settings: { featureFlags },
    } = this.props;
    const availabilityGroup =
      featureFlags.availabilityGroup === undefined ? 'model' : featureFlags.availabilityGroup;
    const showOrSimilar =
      featureFlags.ShowOrSimilar === undefined ? true : featureFlags.ShowOrSimilar;
    const legend = availabilityGroup === 'category' && showOrSimilar ? t('orSimilar') : '';

    return `${car.model.brand.name} ${car.model.name} ${legend}`;
  }

  getCategoryOrGroupName = carModel => {
    const {
      showSipp,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    if (!categoryGroups) return carModel.category.name;

    const categoryGroupName = getSippByCategory(categoryGroups, carModel.category).toUpperCase();
    return showSipp && carModel.sipp
      ? `${categoryGroupName} - ${carModel.sipp}`
      : categoryGroupName;
  };

  isOnlyQuote = () => {
    return this.props.car && this.props.car.onlyQuote;
  };

  generateCheckoutLink = checkoutLink => {
    const {
      settings: { featureFlags },
      profile,
      currentCurrency,
      defaultCurrency,
      webCurrency,
      t,
    } = this.props;
    const mainBtnIsCounter = checkoutLink.Value === false;
    const counterBtnIsDisabled = featureFlags && featureFlags.showPayOnCounterBtn === false;
    if (
      this.isOnlyQuote() ||
      (mainBtnIsCounter && counterBtnIsDisabled) ||
      (profile && profile.isCompany)
    ) {
      return null;
    }

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className="second-btn book-second_button"
        href="#"
        onClick={() => this.goToCheckout(checkoutLink.Value, checkoutLink.Promotion, checkoutLink)}
      >
        {this.props.settings.theme.template !== MODERN_THEME && (
          <i className="fas fa-angle-right" />
        )}
        <span>
          <span className="CheckoutLink-icon">
            {checkoutLink.isCustom && checkoutLink.Icon && <i class={checkoutLink.Icon}></i>}
          </span>
          {tt(checkoutLink.Text, t)}
          <strong>
            {' '}
            <NumberDisplay
              value={
                applyExchangeRate
                  ? getPriceWithExchangeRate(
                      checkoutLink.Price,
                      currentCurrency,
                      defaultCurrency,
                      webCurrency,
                    )
                  : checkoutLink.Price || 10
              }
              smallDecimals={featureFlags.smallDecimals}
            />
          </strong>
        </span>
      </a>
    );
  };

  getButtonName = type => {
    const { t, selectButtonName, selectOnlineButtonName } = this.props;
    let buttonText = '';

    switch (type) {
      case 'onCounter':
        buttonText = selectButtonName ? tt(selectButtonName, t, true) : t('reservarEnMostrador');
        break;
      case 'online':
        buttonText = selectOnlineButtonName ? tt(selectOnlineButtonName, t, true) : t('pagoOnline');
        break;
    }

    return buttonText;
  };

  checkoutLink(checkoutLink) {
    const {
      settings: { featureFlags },
    } = this.props;
    if (featureFlags && featureFlags.onlinePayments) {
      return this.generateCheckoutLink(checkoutLink);
    }
    return null;
  }

  checkoutButton(button) {
    const {
      settings: { featureFlags },
      onlyQuoteBtn,
      t,
      profile,
    } = this.props;
    const carNotAvailableText = featureFlags && featureFlags.carNotAvailableText;

    if (profile && profile.isCompany) {
      return (
        <Btn
          text={t('takeBooking')}
          onClick={() => this.goToCheckout(false, button.Promotion, button)}
          className="mt-1 mb-3"
          icon={button.Icon}
        />
      );
    }

    return (
      <Btn
        text={
          this.isOnlyQuote()
            ? t(onlyQuoteBtn) ||
              (carNotAvailableText ? tt(carNotAvailableText, t) : t('carNotAvailable'))
            : button.Text
        }
        onClick={() => this.goToCheckout(button.Value, button.Promotion, button)}
        className="mt-1 mb-3 book-main_button"
        icon={this.isOnlyQuote() ? null : button.Icon}
        subText={this.isOnlyQuote() ? null : button.subText}
        isCustom={button.isCustom}
        onlyQuote={this.isOnlyQuote()}
        paymentMethod={button.paymentMethod}
      />
    );
  }

  render() {
    const {
      car,
      showDailyPrice,
      settings: {
        featureFlags,
        paymentConfiguration,
        theme,
        configurations,
        configurations: { checkoutCustomButtons },
      },
      franquicia,
      franchiseLink,
      itemMiddleColumnExtraContent,
      alquilerPor,
      background,
      showModelDetail,
      chargedMsg,
      chargedMsg2,
      loggedAsAgency,
      agencyPrice,
      customerPrice,
      salesCommision,
      bookingsConfiguration,
    } = this.props;

    let checkoutButton, checkoutLink;
    checkoutButton = checkoutLink = {
      Text: this.getButtonName('onCounter'),
      Value: false,
      Price: getPriceWithCommission(car.price, paymentConfiguration),
    };

    if (featureFlags && featureFlags.onlinePayments && !this.isOnlyQuote()) {
      if (paymentConfiguration && paymentConfiguration.isOnlinePaymentHighligthed) {
        checkoutButton = {
          ...checkoutButton,
          Text: this.getButtonName('online'),
          Value: true,
        };
      } else {
        checkoutLink = {
          ...checkoutLink,
          Text: this.getButtonName('online'),
          Value: true,
        };
      }
    }

    const showDeposit = featureFlags.showDeposit === undefined ? true : featureFlags.showDeposit;
    this.changePrice(checkoutButton, 'online');
    this.changePrice(checkoutLink, 'counter');

    if (!car) return;
    return theme.template === MODERN_THEME ? (
      <ModernSearchItemRow
        car={car}
        showDeposit={showDeposit}
        showDailyPrice={showDailyPrice}
        featureFlags={featureFlags}
        franquicia={franquicia}
        franchiseLink={franchiseLink}
        itemMiddleColumnExtraContent={itemMiddleColumnExtraContent}
        alquilerPor={alquilerPor}
        background={background}
        checkoutButton={checkoutButton}
        customCheckOutButtons={checkoutCustomButtons}
        checkoutLink={checkoutLink}
        openRate={configurations.openRate}
        isOnlyQuote={this.isOnlyQuote}
        getCarName={this.getCarName}
        getCategoryOrGroupName={this.getCategoryOrGroupName}
        hasLimitedAvailability={this.hasLimitedAvailability}
        parsePartialAvailabilityAlert={this.parsePartialAvailabilityAlert}
        handleCheckoutLink={this.checkoutLink}
        handleCheckoutBtn={this.checkoutButton}
        showModelDetail={showModelDetail}
        currentCurrency={this.props.currentCurrency}
        defaultCurrency={this.props.defaultCurrency}
        webCurrency={this.props.webCurrency}
        chargedMsg={chargedMsg}
        chargedMsg2={chargedMsg2}
        loggedAsAgency={loggedAsAgency}
        agencyPrice={agencyPrice}
        customerPrice={customerPrice}
        salesCommision={salesCommision}
        distanceUnit={bookingsConfiguration?.distanceUnit}
      />
    ) : (
      <DefaultSearchItemRow
        car={car}
        showDeposit={showDeposit}
        showDailyPrice={showDailyPrice}
        featureFlags={featureFlags}
        franquicia={franquicia}
        franchiseLink={franchiseLink}
        itemMiddleColumnExtraContent={itemMiddleColumnExtraContent}
        alquilerPor={alquilerPor}
        background={background}
        openRate={configurations.openRate}
        checkoutButton={checkoutButton}
        checkoutLink={checkoutLink}
        customCheckOutButtons={checkoutCustomButtons}
        isOnlyQuote={this.isOnlyQuote}
        getCarName={this.getCarName}
        getCategoryOrGroupName={this.getCategoryOrGroupName}
        hasLimitedAvailability={this.hasLimitedAvailability}
        parsePartialAvailabilityAlert={this.parsePartialAvailabilityAlert}
        handleCheckoutLink={this.checkoutLink}
        handleCheckoutBtn={this.checkoutButton}
        showModelDetail={showModelDetail}
        currentCurrency={this.props.currentCurrency}
        defaultCurrency={this.props.defaultCurrency}
        webCurrency={this.props.webCurrency}
        chargedMsg={chargedMsg}
        chargedMsg2={chargedMsg2}
        loggedAsAgency={loggedAsAgency}
        agencyPrice={agencyPrice}
        customerPrice={customerPrice}
        salesCommision={salesCommision}
        distanceUnit={bookingsConfiguration?.distanceUnit}
      />
    );
  }
}
const mapStateToProps = ({ siteConfiguration, searchCars, user, global, bookings }) => ({
  settings: siteConfiguration.settings,
  searchCars,
  profile: user.profile,
  currentCurrency: global.currentCurrency,
  defaultCurrency: global.defaultCurrency,
  webCurrency: global.webCurrency,
  bookingsConfiguration: bookings.configuration,
});
export default withRouter(
  connect(mapStateToProps, { setCheckout, fetchPrices, setSearchCarParams })(
    withTranslation()(SearchItemRow),
  ),
);
