import React from 'react';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import Btn from 'components/Btn';
import CheckboxInput from 'components/CheckBoxInput';
import RangeDateInput from 'components/RangeDateInput';
import SearchBoxPlace from 'components/SearchBoxPlace';
import SelectInput from 'components/SelectInput';
import TextInput from 'components/TextInput';
import withSettingsContext from 'SettingsContext';
import { StyledSearchForm } from './index.styled';
import { withTheme } from 'styled-components';
import { tt } from 'utils/translationHelper';
class DefaultSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      loading: false,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  handleResize() {
    const isMobile = window.innerWidth <= 600;
    this.setState({ isMobile });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    this.props.submitSearch();
  };

  render() {
    const {
      searchText,
      className,
      featureFlags,
      configurations,
      promotionCodeText,
      transparent,
      checkoutText,
      showCity,
      showIATA,
      showDifferentDropPlace,
      orientation,
      focus,
      withFullScreenPortal,
      pickUp,
      dropOff,
      promotionCode,
      showInvalidPromotionMessage,
      kmFilter,
      minimumStay,
      t,
      theme,
      pickUpSelect,
      toggleDropInput,
      dropOffSelect,
      filteredDropPlaces,
      handleDateSelect,
      handleDateFocus,
      isDayBlocked,
      pickUpHourSelection,
      dropOffHourSelection,
      onPromotionCodeChange,
      handleKmFilter,
      disableSubmit,
      maximumStay,
      driverMinAge,
      driverMaxAge,
      driverAge,
      driverAgeWarning,
      invalidDriverAge,
      handleDriverAgeChange,
      resultsPage,
      isHome,
      commercialAgreements,
      onCommercialAgreementChange,
      commercialAgreementCode,
      isCompany,
      useDriverAgeWithoutCaution,
      driverAgeAllowedMin,
      driverAgeAllowedMax,
      useBackendSort,
      customText,
      pickUpPlaces,
      distanceUnit,
    } = this.props;

    return (
      <StyledSearchForm
        onSubmit={e => e.preventDefault(false)}
        className={'pt-4 pb-4 pl-4 pr-4 ' + (className || '')}
        transparent={transparent}
      >
        <div className="row mt-2">
          <div className="col-md-12 pickup-place-picker">
            <label htmlFor="selectFromPlace" className="label">
              {t('deliveryPlace')}
            </label>
            <SearchBoxPlace
              name="selectFromPlace"
              data={pickUpPlaces}
              placeChangeHandler={pickUpSelect}
              placeSelected={pickUp.place}
              placeholder={t('selectPickUpPlace')}
              showCity={showCity || false}
              showIATA={showIATA || false}
              useBackendSort={useBackendSort}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 return-place-picker">
            {pickUp && pickUp.place && pickUp.place.value && (
              <span className="diff-drop-place" onClick={toggleDropInput}>
                {t('differentreturnplace')}
              </span>
            )}
            {showDifferentDropPlace && (
              <>
                <label htmlFor="selectToPlace" className="label">
                  {t('LugarDeDevolucion')}
                </label>
                <div className="col-md-12 p-0 mb-4">
                  <SearchBoxPlace
                    name="selectToPlace"
                    className="search-box-place"
                    data={filteredDropPlaces()}
                    placeChangeHandler={dropOffSelect}
                    placeSelected={dropOff.place}
                    placeholder={t('SeleccionarLugarDeDevolucion')}
                    showCity={showCity || false}
                    showIATA={showIATA || false}
                    useBackendSort={useBackendSort}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="d-flex row mt-1">
          <div className="col-xs-12 col-md-6 range-date-picker">
            <RangeDateInput
              label={t('deliveryReturnDate')}
              onDatesChange={handleDateSelect}
              onFocusChange={handleDateFocus}
              startDateId="drop-up-date-start"
              startDate={pickUp.moment}
              endDate={dropOff.moment}
              icon="far fa-calendar-alt"
              iconColor={theme.colors.buttonPrimaryBgHover}
              isDayBlocked={isDayBlocked}
              datePickerOptions={{
                minimumNights:
                  configurations.minBookingLength === undefined
                    ? minimumStay
                    : configurations.minBookingLength,
                focusedInput: focus,
                orientation: this.state.isMobile ? 'vertical' : orientation,
                withFullScreenPortal: this.state.isMobile ? true : withFullScreenPortal,
                customArrowIcon: <span className="fas fa-arrow-right p-0 mr-2" />,
                startDatePlaceholderText: t('deliveryDate'),
                endDatePlaceholderText: t('returnDate'),
                hideKeyboardShortcutsPanel: true,
              }}
              isOutsideRange={day => {
                if (maximumStay) return day.isAfter(maximumStay);
                const today = moment().startOf('day');
                return day.isBefore(today);
              }}
            />
          </div>

          <div className="col-md-3 col-sm-6 col-xs-6 hour-select hour-select-from">
            <SelectInput
              onChange={pickUpHourSelection}
              label={t('deliveryTime')}
              icon="far fa-clock"
              selected={pickUp.hour}
              iconColor={theme.colors.buttonPrimaryBgHover}
              options={pickUp.hours}
              name="pickupTime"
            />
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6 hour-select hour-select-to">
            <SelectInput
              name="returnTime"
              onChange={dropOffHourSelection}
              label={t('returnTime')}
              icon="far fa-clock"
              selected={dropOff.hour}
              iconColor={theme.colors.buttonPrimaryBgHover}
              options={dropOff.hours}
            />
            {dropOff.checkoutTime && (
              <div
                style={{
                  whiteSpace: 'break-spaces',
                  fontSize: '9px',
                  color: '#ff0000',
                }}
              >
                {t(checkoutText) || t('checkoutMsg')} ({dropOff.checkoutTime})
              </div>
            )}
          </div>
        </div>

        <div className="row">
          {featureFlags.showPromotionCode & !isCompany ? (
            <div className={`col-md-${resultsPage ? 6 : 4} mb-2 mt-1`}>
              <TextInput
                className="promotion-code-input"
                label={t('CodigoDePromocion')}
                icon="fas fa-star"
                iconColor={theme.colors.buttonPrimaryBgHover}
                placeholder={t(promotionCodeText) || t('EscribiTuCodigo')}
                onChange={onPromotionCodeChange}
                disabled={isCompany ? true : false}
                value={promotionCode || ''}
                error={showInvalidPromotionMessage ? t('CodigoDePromocionInvalido') : ''}
              />
            </div>
          ) : (
            <div className={`col-md-${resultsPage ? 6 : 4} mb-2 mt-1`}></div>
          )}
          {!isHome && isCompany && commercialAgreements && commercialAgreements.length > 0 && (
            <div className="col-12">
              <SelectInput
                name="commercial-agreement-code"
                onChange={onCommercialAgreementChange}
                label={t('commercialAgreement')}
                icon="fas fa-file-contract"
                selected={commercialAgreementCode}
                iconColor={theme.colors.buttonPrimaryBgHover}
                options={commercialAgreements}
              />
            </div>
          )}
          {featureFlags && featureFlags.kilometerFilters && (
            <div className={`col-md-${resultsPage ? 6 : 4} mb-2 mt-1 filtro-km`}>
              <CheckboxInput
                className="special-checkbox"
                text={
                  t(customText) ||
                  t(distanceUnit === 'Kilometers' ? 'KmIlimitados' : 'MillasIlimitados')
                }
                checked={kmFilter.selected === true}
                onChange={e => handleKmFilter(e)}
              />
            </div>
          )}
          {featureFlags && featureFlags.showDriverAge && (
            <>
              <div className={`col-md-${resultsPage ? 6 : 4} mb-2 mt-1 driver-age`}>
                {!useDriverAgeWithoutCaution ? (
                  <div className="special-label">
                    <label htmlFor="driverAgeWithoutCautionLabel" className="label">
                      {t('driverAgeBetween', {
                        min: driverAgeAllowedMin,
                        max: driverAgeAllowedMax,
                      })}
                    </label>
                    <Tooltip
                      placement=""
                      title={t('driverAgeAllowedBetween', {
                        min: driverAgeAllowedMin,
                        max: driverAgeAllowedMax,
                      })}
                      className=""
                    >
                      <i className="fas fa-info-circle"></i>
                    </Tooltip>
                  </div>
                ) : (
                  <div className="special-checkbox">
                    <CheckboxInput
                      text={t('driverAgeBetween', {
                        min: driverMinAge,
                        max: driverMaxAge,
                      })}
                      checked={driverAge.betweenMinAndMax}
                      onChange={e => handleDriverAgeChange(e)}
                    />
                    <Tooltip
                      placement="top"
                      title={
                        tt(driverAgeWarning, t) ||
                        t('driverAgeWarning', {
                          min: driverMinAge,
                          max: driverMaxAge,
                        })
                      }
                    >
                      <i className="fas fa-info-circle"></i>
                    </Tooltip>
                  </div>
                )}
              </div>
              {!driverAge.betweenMinAndMax && (
                <div className={`col-md-${resultsPage ? 6 : 4} mb-2 mt-1`}>
                  <div className="driver-age-input">
                    <TextInput
                      label={t('driverAge')}
                      onChange={value => handleDriverAgeChange(false, value)}
                      value={driverAge.age || ''}
                      type="number"
                      error={driverAge.age && invalidDriverAge ? t('driverAgeError') : ''}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="row mt-4 mb-3 submit-block">
          <div className="col-md-12">
            <Btn
              className="submit-btn search-form_button"
              text={tt(searchText) || t('search')}
              onClick={() => this.handleSubmit()}
              disabled={disableSubmit() || this.state.loading}
            />
          </div>
        </div>
      </StyledSearchForm>
    );
  }
}

export default withSettingsContext(withTheme(withTranslation()(DefaultSearchForm)));
