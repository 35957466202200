import SearchForm from './SearchForm';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  setSearchCarParams,
  fetchBookeableCars,
  fetchPlaces,
  fetchLanguages,
  fetchSchedules,
  fetchHolidays,
} from 'actions/';

const mapStateToProps = ({ router, global, siteConfiguration, searchCars, user }) => ({
  router,
  profile: user.profile,
  places: global.listPlaces,
  featureFlags: siteConfiguration.settings.featureFlags,
  configurations: siteConfiguration.settings.configurations,
  holidays: global.listHolidays,
  schedules: global.listSchedules,
  params: searchCars.params,
  searchCars,
});

export default withRouter(
  connect(mapStateToProps, {
    setSearchCarParams,
    fetchBookeableCars,
    fetchPlaces,
    fetchLanguages,
    fetchSchedules,
    fetchHolidays,
  })(SearchForm),
);
