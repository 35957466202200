import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { setSearchCarParams } from 'actions';
import { Btn, Title, Paragraph, ModelDetail, NumberDisplay } from 'components/';
import { props } from './../FlotaItem.props';
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Removed LazyLoadImage import
import 'react-lazy-load-image-component/src/effects/blur.css';
class DefaultFlotaItem extends React.Component {
  static propTypes = props;

  render() {
    const {
      t,
      model,
      currency,
      handleButtonClick,
      getCategoryOrGroupName,
      getCarImage,
      prefixForNumeric,
      featureFlags,
      oneSlide,
      showModelDetail,
    } = this.props;

    return (
      <div className={`car-item pl-4 pr-4 ${oneSlide ? 'one-slide' : ''}`}>
        <div className={`car-image mb-4 ${oneSlide ? 'one-slide' : ''}`}>
          <LazyLoadImage
            src={getCarImage(model)}
            alt={`${model.brand.name} ${model.name}`}
            threshold={100}
            effect="blur"
            width={'100%!important'}
            height={'10rem'}
            wrapperClassName="car-image-wrapper"
          />
        </div>

        <div className="car-details">
          <Title
            className="car-name"
            text={`${model.brand.name} ${model.name}`}
            italic={false}
            type="p"
            fontSize={18}
            weight="700"
            showTooltip
          />
          <Paragraph
            className="car-category"
            text={`${t('category')} ${getCategoryOrGroupName(model)}`}
            nonTranslate
          />
          <ModelDetail
            model={model}
            prefixForNumeric={prefixForNumeric}
            showModelDetail={showModelDetail === false ? showModelDetail : true}
          />

          <div className="price">
            {featureFlags &&
              featureFlags.showFleetPrice &&
              (!featureFlags.fleetUseLowerPrice && model.dailyPrice > 0 ? (
                <p>
                  {t('from')} <span>{currency != null ? currency.prefix : 'ARS'} </span>
                  <strong>
                    <NumberDisplay value={model.dailyPrice} />
                  </strong>{' '}
                  {t('perday')}
                </p>
              ) : (
                featureFlags.showFleetPrice &&
                featureFlags.fleetUseLowerPrice &&
                model.lowerPrice > 0 && (
                  <p>
                    {t('from')} <span>{currency != null ? currency.prefix : 'ARS'} </span>
                    <strong>
                      <NumberDisplay value={model.lowerPrice} />
                    </strong>{' '}
                    {t('perday')}
                  </p>
                )
              ))}
            <Btn
              text={(featureFlags && t(featureFlags.bookingButtonText)) || t('booknow')}
              onClick={handleButtonClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, { setSearchCarParams })(
  withRouter(withTranslation()(DefaultFlotaItem)),
);
