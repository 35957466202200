import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { tlink } from '../../../../utils/translationHelper';
import { setSearchCarParams } from 'actions';
import { getSippByCategory } from '../../../../utils/categoryGroupHelper';
import DefaultFlotaItem from './Default';
import ModernFlotaItem from './Modern';
import { MODERN_THEME } from 'styled/constants';
import { props, defaultProps } from './FlotaItem.props';
import { withTranslation } from 'react-i18next';

class FlotaItem extends React.Component {
  static propTypes = props;
  static defaultProps = defaultProps;
  constructor() {
    super();
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.getCategoryOrGroupName = this.getCategoryOrGroupName.bind(this);
    this.getCarImage = this.getCarImage.bind(this);
  }

  handleButtonClick = () => {
    const { history, bookLink, t, i18n, setSearchCarParams, model, settings } = this.props;
    setSearchCarParams({ categories: [model.category.id] });

    history.push(tlink(bookLink, t, i18n, null, settings.configurations.langConfig));
    window.scrollTo(0, 0);
  };

  getCategoryOrGroupName = (carModel) => {
    const {
      showSipp,
      settings: {
        configurations: { categoryGroups },
      },
    } = this.props;
    if (!categoryGroups) return carModel.category.name;

    const categoryGroupName = getSippByCategory(categoryGroups, carModel.category).toUpperCase();
    return showSipp && carModel.sipp
      ? `${categoryGroupName} - ${carModel.sipp}`
      : categoryGroupName;
  };

  getCarImage = (model) => {
    if (model.imagePath) return model.imagePath;
    return model.category.imagePath;
  };

  render() {
    const {
      model,
      settings: { currency, theme, featureFlags },
      prefixForNumeric,
      oneSlide,
      showModelDetail,
    } = this.props;

    return theme.template === MODERN_THEME ? (
      <ModernFlotaItem
        model={model}
        currency={currency}
        prefixForNumeric={prefixForNumeric}
        handleButtonClick={this.handleButtonClick}
        getCategoryOrGroupName={this.getCategoryOrGroupName}
        getCarImage={this.getCarImage}
        featureFlags={featureFlags && featureFlags}
        oneSlide={oneSlide}
        showModelDetail={showModelDetail || false}
      />
    ) : (
      <DefaultFlotaItem
        model={model}
        currency={currency}
        prefixForNumeric={prefixForNumeric}
        handleButtonClick={this.handleButtonClick}
        getCategoryOrGroupName={this.getCategoryOrGroupName}
        getCarImage={this.getCarImage}
        featureFlags={featureFlags}
        oneSlide={oneSlide}
        showModelDetail={showModelDetail}
      />
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});

export default connect(mapStateToProps, { setSearchCarParams })(
  withRouter(withTranslation()(FlotaItem)),
);
