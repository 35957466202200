import React from 'react';
import { PropTypes } from 'prop-types';
import { CompositeComponent } from 'components/';
import { Column } from '..';
import style from 'styled-components';

const StyledRow = style.div`
  background: ${props => props.background || props.theme.colors.bb}
`;

export default class ColumnsSectionComponent extends CompositeComponent {
  static propTypes = {
    sections: PropTypes.array,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    order: PropTypes.number,
    mobileOrder: PropTypes.number,
    className: PropTypes.string,
    noWrapper: PropTypes.bool,
  };

  withWrapper(config, column, myProps, key) {
    if (config.component !== 'Column') {
      const c = {
        sm: myProps.sm || config.sm,
        md: myProps.md || config.md,
        lg: myProps.lg || config.lg,
        order: config.order || undefined,
        mobileOrder: config.mobileOrder || undefined,
        className: config.className || '',
      };

      return (
        <Column {...c} key={key}>
          {column}
        </Column>
      );
    }

    return column;
  }

  render() {
    const { className, noWrapper } = this.props;

    return (
      <StyledRow className={`row ${className || ''}`} background={this.props.background}>
        {this.renderChilds(noWrapper ? null : this.withWrapper, {
          ...this.props,
        })}
      </StyledRow>
    );
  }
}
