import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { CheckboxInput, NumberDisplay, Title, Paragraph } from 'components';
import { withRouter } from 'react-router-dom';
import { StyledPaymentMethod } from './PaymentMethods.styled';
import { StyledPaymentMethodModern } from './PaymentMethods.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { tt } from 'utils/translationHelper';
import { handleGetTotalToPay } from 'utils/dataHelper';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';
import PartialPayWarning from 'sections/payment/partialPayWarning';

class PaymentMethods extends React.Component {
  state = {
    paymentMethodsList: this.props.paymentMethods,
    selectedMethodId: null,
    qsamount: 0,
    paymentMethodsListFiltered: [],
  };

  componentWillMount() {
    const { location } = this.props;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      let partial = parseFloat(parseQs.amount);
      if (partial != 0) {
        this.setState({ qsamount: partial });
      }
    }
  }

  componentDidMount() {
    this.getFilteredAvailablePaymentMethodsByBranchOffice();
  }

  onAddingItem = i => event => {
    const { onChange } = this.props;
    this.setState({ selectedMethodId: i });
    //return onChange(!this.state.paymentMethodsList[i].isChecked, this.state.paymentMethodsList[i]);
    return onChange(
      !this.state.paymentMethodsListFiltered[i].isChecked,
      this.state.paymentMethodsListFiltered[i],
    );
  };

  getTotalToPay() {
    const {
      bookings,
      settings: { paymentConfiguration },
      infractions,
    } = this.props;
    const { qsamount } = this.state;

    if (bookings.confirmation) {
      const useRound = bookings.confirmation.customerBalance % 1 === 0;
      const price = handleGetTotalToPay(
        bookings.confirmation.customerBalance,
        paymentConfiguration,
        qsamount,
      );

      return useRound ? Math.round(price) : price;
    } else if (infractions.current) {
      return infractions.current.amount;
    }
  }

  getBookTotalToPay() {
    const { booking, settings } = this.props;
    const useRound = booking.price % 1 === 0;
    let toPay = Math.abs(booking.price); // Guarda el valor actual en una variable local

    if (booking.isPartial && booking.partialPaymentAmount) {
      settings.paymentConfiguration.percentageToPay = booking.partialPaymentAmount;
      toPay = Math.abs((booking.price / 100) * settings.paymentConfiguration.percentageToPay);
    }

    if (booking.isOnline) {
      settings.paymentConfiguration.percentageToPay = null;
    }

    if (settings.paymentConfiguration && settings.paymentConfiguration.percentageToPay) {
      toPay = Math.abs((booking.price / 100) * settings.paymentConfiguration.percentageToPay);
    }

    return useRound ? Math.round(toPay) : toPay;
  }

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledPaymentMethodModern;
    }
    return StyledPaymentMethod;
  }

  getPaymentTypeIdByComponentName = componentName => {
    switch (componentName) {
      case 'CecabankComponent':
        return 19;
      case 'CredomaticComponent':
        return 12;
      case 'FiservIpgComponent':
        return 18;
      case 'GeniusComponent':
        return 20;
      case 'MPComponent':
        return 3;
      case 'PaypalComponent':
        return 6;
      case 'PoyntComponent':
        return 17;
      case 'PrismaComponent':
        return 15;
      case 'PromericaComponent':
        return 13;
      case 'StripeComponent':
        return 14;
      case 'TransbankComponent':
        return 7;
    }
    return 0;
  };

  getFilteredAvailablePaymentMethodsByBranchOffice() {
    let { paymentMethodsList } = this.state;
    const { booking, bookings, infractions, generalSettings } = this.props;

    let branchOfficeId = booking
      ? booking.deliveryPlace?.branchOfficeId
      : bookings.confirmation?.deliveryPlace?.branchOfficeId;

    if (branchOfficeId == null)
      branchOfficeId = infractions.current?.booking?.deliveryPlace?.branchOfficeId;

    if (generalSettings && generalSettings.paymentGatewayConfigs) {
      let paymentMethodsListFiltered = [];
      paymentMethodsList.forEach(p => {
        let paymentTypeId = this.getPaymentTypeIdByComponentName(p.component);
        if (paymentTypeId == 0) {
          paymentMethodsListFiltered.push(p);
        } else {
          let gatewayConfigs = generalSettings.paymentGatewayConfigs.filter(
            x => x.paymentType === paymentTypeId,
          );
          if (gatewayConfigs.length > 0) {
            gatewayConfigs.every(gc => {
              if (
                !gc.branchOfficeIds.length ||
                gc.branchOfficeIds.some(s => s === branchOfficeId)
              ) {
                paymentMethodsListFiltered.push(p);
                return false;
              }
              return true;
            });
          }
        }
      });
      this.setState({ paymentMethodsListFiltered: paymentMethodsListFiltered });
    } else {
      this.setState({ paymentMethodsListFiltered: paymentMethodsList });
    }
  }

  render() {
    const {
      t,
      booking,
      error,
      settings,
      paymentTitle,
      currentCurrency,
      defaultCurrency,
      webCurrency,
      chargedMsg,
      chargedMsg2,
    } = this.props;

    let price;

    if (booking) {
      price = this.getBookTotalToPay();
    } else {
      price = this.getTotalToPay();
    }

    const modernTheme = settings.theme.template;

    let { paymentMethodsListFiltered } = this.state;

    const StyledComponent = this.getTemplateStyledComponent();

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    return (
      <StyledComponent>
        <hr className="separator mb-5" />
        <div className="container-fluid p-0">
          <div className="row">
            <div className="mb-4">
              <Title
                type="h2"
                text={paymentTitle ? tt(paymentTitle, t) : t('PaymentMethod')}
                weight="700"
                fontSize={20}
                italic={false}
              />
            </div>
            <PartialPayWarning className="mb-5" />
          </div>
        </div>
        <div className="form-group conditions">
          {paymentMethodsListFiltered?.map((paymentMethod, i) => {
            return (
              <div key={i}>
                <div className="row paymentRow">
                  <div className="col-md-7 name">
                    <CheckboxInput
                      onChange={this.onAddingItem(i)}
                      checked={this.state.selectedMethodId === i}
                      text={<span>{t(paymentMethod.name)}</span>}
                    />
                    <img
                      className="image"
                      width="110"
                      height="30"
                      src={paymentMethod.img}
                      alt={paymentMethod.name}
                    />
                  </div>
                  <div className="col-md-5 price">
                    <NumberDisplay
                      value={
                        applyExchangeRate
                          ? getPriceWithExchangeRate(
                              price,
                              currentCurrency,
                              defaultCurrency,
                              webCurrency,
                            )
                          : price
                      }
                      currentCurrency={currentCurrency}
                    />
                  </div>
                </div>
                {!modernTheme && <hr className="separator mb-4" />}
                {showExchangeMsg && (
                  <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                    <strong>{`${tt(chargedMsg, t) || t('chargedMsg')} ${
                      currentCurrency?.isoCode
                    } ${tt(chargedMsg2, t) || t('chargedMsg2')} ${
                      currentCurrency?.exchangeRate
                    }`}</strong>
                  </Paragraph>
                )}
              </div>
            );
          })}
          {error && <div className="error">{error}</div>}
        </div>
      </StyledComponent>
    );
  }
}

const mapStateToProps = ({ siteConfiguration, searchCars, bookings, infractions, global }) => ({
  settings: siteConfiguration.settings,
  booking: searchCars.checkout.booking,
  infractions: infractions,
  onlinePayment: searchCars.checkout.onlinePayment,
  bookingParams: searchCars.params,
  bookings: bookings,
  generalSettings: global.generalSettings,
  currentCurrency: global.currentCurrency,
  defaultCurrency: global.defaultCurrency,
  webCurrency: global.webCurrency,
});

export default connect(mapStateToProps)(withRouter(withTranslation()(PaymentMethods)));
