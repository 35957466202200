import React from 'react';
import { props } from '../SearchItemRow.props';
import { SearchItemRowStyled } from './index.styled';
import {
  NumberDisplay,
  TranslatedComponent,
  Paragraph,
  ModelDetail,
  TranslatedLink,
} from 'components/';
import { withTranslation } from 'react-i18next';
import { tt } from 'utils/translationHelper';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';

class DefaultSearchItemRow extends TranslatedComponent {
  static propTypes = props;

  render() {
    const {
      car: {
        car,
        category,
        franchise,
        totalDaysString,
        ilimitedKm,
        fromDate,
        toDate,
        maxAllowedDistanceByDay,
      },
      t,
      showDailyPrice,
      featureFlags,
      featureFlags: { smallDecimals },
      franquicia,
      franchiseLink,
      itemMiddleColumnExtraContent,
      alquilerPor,
      background,
      showDeposit,
      checkoutButton,
      customCheckOutButtons,
      isOnlyQuote,
      getCarName,
      getCategoryOrGroupName,
      hasLimitedAvailability,
      parsePartialAvailabilityAlert,
      checkoutLink,
      handleCheckoutLink,
      handleCheckoutBtn,
      openRate,
      showModelDetail,
      currentCurrency,
      defaultCurrency,
      webCurrency,
      chargedMsg,
      chargedMsg2,
      loggedAsAgency,
      agencyPrice,
      customerPrice,
      salesCommision,
      distanceUnit,
    } = this.props;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);
    const showExchangeMsg = applyExchangeRate;

    const availabilityGroup = featureFlags.availabilityGroup || 'model';

    const franchiseAmount = availabilityGroup === 'category' ? category.franchise : franchise;
    const franchiseInCurrency = applyExchangeRate
      ? getPriceWithExchangeRate(franchiseAmount, currentCurrency, defaultCurrency, webCurrency)
      : franchiseAmount;

    const FranchiseInfo = () => {
      const franchiseContent = (
        <>
          <i
            className="fas fa-info-circle mr-2"
            data-toggle="tooltip"
            title={
              (featureFlags && featureFlags.tooltipText && tt(featureFlags.tooltipText, t)) ||
              t(franquicia) ||
              t('franchiseHelpText')
            }
          />
          <strong>{t(franquicia) || t('franquicia')} </strong>
          <NumberDisplay value={franchiseInCurrency} smallDecimals={smallDecimals} />
        </>
      );

      return (
        <>
          {franchiseLink ? (
            <TranslatedLink path={franchiseLink} className="franchise mt-0">
              {franchiseContent}
            </TranslatedLink>
          ) : (
            <p className="franchise mt-0">{franchiseContent}</p>
          )}
          {showExchangeMsg && (
            <Paragraph className="rent-days mb-0 mt-2 md-hidden" color={'red'}>
              <strong>{`${tt(chargedMsg, t) || t('chargedMsg')} ${currentCurrency?.isoCode} ${tt(
                chargedMsg2,
                t,
              ) || t('chargedMsg2')} ${currentCurrency?.exchangeRate}`}</strong>
            </Paragraph>
          )}
        </>
      );
    };

    if (!car) return;

    const noVatPrice = featureFlags && featureFlags.ShowNoVatPrice;

    let buttonPrice = noVatPrice ? checkoutButton.noVatPrice : checkoutButton.Price;
    if (applyExchangeRate) {
      buttonPrice = getPriceWithExchangeRate(
        buttonPrice,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );
    }

    const kmText =
      ilimitedKm === true
        ? t(distanceUnit == 'Kilometers' ? 'KmIlimitados' : 'MillasIlimitados')
        : t(distanceUnit == 'Kilometers' ? 'KmDiarios' : 'MillasDiarios', {
            km: maxAllowedDistanceByDay,
          });

    return (
      <SearchItemRowStyled
        className="row mb-3 mr-0 ml-0 d-flex search-item-row car-item pt-4 pb-4 pl-4 pr-4"
        background={background}
      >
        <div className="col-lg-9 col-md-12 car-description">
          <div className="car-image mr-4">
            <img className="img-fluid" src={car.model.imagePath} alt={car.model.name} />
          </div>
          <div className="car-info">
            <h2 className="car-name">{getCarName(car)}</h2>
            <Paragraph
              className="car-category"
              text={`${t('category')} ${getCategoryOrGroupName(car.model)}
                <span class="mr-2 ml-2 separator md-hidden">|</span>
                <span class="tachometer md-hidden"><i class="fas fa-tachometer-alt mr-1"></i>
                ${kmText}
                </span>`}
              nonTranslate
            />
            <div className="car-details md-hidden">
              <ModelDetail model={car.model} showModelDetail={showModelDetail} />
              {showDeposit && <FranchiseInfo />}
              {itemMiddleColumnExtraContent && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(itemMiddleColumnExtraContent),
                  }}
                />
              )}
            </div>
            {hasLimitedAvailability() &&
              parsePartialAvailabilityAlert('desktop', t, fromDate, toDate)}
          </div>
        </div>
        {hasLimitedAvailability() && parsePartialAvailabilityAlert('mobile', t, fromDate, toDate)}
        <div className="col-md-12 car-mobile-details text-left mt-2">
          <ModelDetail model={car.model} showModelDetail={showModelDetail} />
          <div className="d-flex">
            {showDeposit && (
              <>
                <FranchiseInfo />
                <span className="mr-2 ml-2 separator">|</span>
              </>
            )}
            <Paragraph
              className="car-category"
              text={`<span class="tachometer"><i class="fas fa-tachometer-alt mr-1"></i>
                      ${kmText}
                      </span>
                `}
              nonTranslate
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-12 car-price">
          <Paragraph className="rent-days mb-0 mt-2">
            {alquilerPor || t('alquilerPor')}
            <span>
              {' '}
              <strong>{totalDaysString}</strong>{' '}
            </span>
            {openRate && t('from').toLowerCase()}
          </Paragraph>
          {(!isOnlyQuote() || (isOnlyQuote() && featureFlags.showPriceToRequest)) && (
            <Paragraph className="rent-price mt-0 mb-0">
              <NumberDisplay
                value={buttonPrice}
                smallDecimals={smallDecimals}
                currentCurrency={currentCurrency}
              />
            </Paragraph>
          )}
          {showDailyPrice &&
          (!isOnlyQuote() || (isOnlyQuote() && featureFlags.showPriceToRequest)) ? (
            <Paragraph className="daily-price mt-0 mb-0">
              {t('dailyPrice')}{' '}
              <strong>
                <NumberDisplay
                  smallDecimals={smallDecimals}
                  value={buttonPrice / totalDaysString}
                />
              </strong>
            </Paragraph>
          ) : null}
          {customCheckOutButtons &&
            customCheckOutButtons?.map(button =>
              button.paymentMethod === 'counter'
                ? ((button.Price = buttonPrice), handleCheckoutLink(button))
                : handleCheckoutBtn(button),
            )}
          {!customCheckOutButtons && handleCheckoutBtn(checkoutButton)}
          {!customCheckOutButtons && handleCheckoutLink(checkoutLink)}
          {loggedAsAgency && (
            <>
              <Paragraph className="daily-price mt-0 mb-0">
                {t('myAccount.customer')}:{' '}
                <strong>
                  <NumberDisplay
                    value={customerPrice}
                    smallDecimals={smallDecimals}
                    currentCurrency={currentCurrency}
                  />
                </strong>
              </Paragraph>
              <Paragraph className="daily-price mt-0 mb-0">
                {t('myAccount.agency')}:{' '}
                <strong>
                  <NumberDisplay
                    value={agencyPrice}
                    smallDecimals={smallDecimals}
                    currentCurrency={currentCurrency}
                  />
                </strong>
              </Paragraph>
              <Paragraph className="daily-price mt-0 mb-0">
                {t('myAccount.commision')}:{' '}
                <strong>
                  <NumberDisplay
                    value={salesCommision}
                    smallDecimals={smallDecimals}
                    currentCurrency={currentCurrency}
                  />
                </strong>
              </Paragraph>
            </>
          )}
        </div>
      </SearchItemRowStyled>
    );
  }
}

export default withTranslation()(DefaultSearchItemRow);
