import React from 'react';
import { props } from './Footer.props';
import { StyledFooter } from './Footer.styled';
import { CompanyInfo, LinkList, SocialList } from '../';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { tt } from 'utils/translationHelper';

class Footer extends React.Component {
  static propTypes = props;

  constructor(props) {
    super(props);
    this.element = React.createRef();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setPadding();
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    setTimeout(() => {
      this.setPadding();
    }, 300);
  }

  setPadding() {
    const {
      settings: {
        configurations: { hideRentlyFooter },
      },
    } = this.props;

    if (this.element.current) {
      let height = this.element.current.offsetHeight;
      if (!hideRentlyFooter) height += 32;

      this.element.current.parentElement.style.paddingBottom = height + 'px';
    }
  }

  render() {
    const {
      settings: {
        configurations: { sitemap },
      },
      settings: {
        configurations: { socialNetworks },
      },
      showSocialsOnRight,
      collapsible,
      seeLess,
      seeMore,
      t,
    } = this.props;

    const compInfo = { ...this.props.companyInfo, showSocialsOnRight };
    compInfo.socialNetworks = socialNetworks;

    return (
      <>
        <StyledFooter
          ref={this.element}
          borderSize="3px"
          borderStyle="solid"
          className="pb-5 pt-5"
          height={this.props.height}
          padding={this.props.padding}
          width="100%"
          bgcolor={this.props.bgcolor}
          color={this.props.color}
          hover={this.props.hover}
          companyInfoTitleColor={this.props.companyInfo.titleColor}
          companyInfoTextColor={this.props.companyInfo.textColor}
          socialColor={this.props.socialColor}
        >
          {compInfo && <CompanyInfo {...compInfo} />}

          <div className="footerColumn footerLists">
            {this.props.customSitemap ? (
              <LinkList
                {...this.props.customSitemap}
                className={`footerColumn footerLinkList md-hide lg-hide col ${
                  this.props.customSitemap.className ? this.props.customSitemap.className : ''
                }`}
              />
            ) : (
              sitemap && (
                <LinkList
                  {...sitemap}
                  className={`footerColumn footerLinkList md-hide lg-hide col ${
                    sitemap.className ? sitemap.className : ''
                  }`}
                />
              )
            )}
            {this.props.access && (
              <LinkList
                {...this.props.access}
                className={`footerColumn footerLinkList md-hide lg-hide col ${
                  this.props.access.className ? this.props.access.className : ''
                }`}
              />
            )}
            <div className="footerColumn md-hide">
              {this.props.doubts && (
                <LinkList
                  {...this.props.doubts}
                  className={`footerLinkList col ${
                    this.props.doubts.className ? this.props.doubts.className : ''
                  }`}
                />
              )}
              {this.props.contact && (
                <LinkList
                  {...this.props.contact}
                  className={`footerLinkList col ${
                    this.props.contact.className ? this.props.contact.className : ''
                  }`}
                />
              )}
            </div>
            <div className="footerColumn md-hide">
              {this.props.safeSite && (
                <LinkList
                  {...this.props.safeSite}
                  className={`footerLinkList col ${
                    this.props.safeSite.className ? this.props.safeSite.className : ''
                  }`}
                  collapsible={collapsible}
                  seeLess={tt(seeLess, t)}
                  seeMore={tt(seeMore, t)}
                  type="components"
                />
              )}
              {showSocialsOnRight && (
                <SocialList links={socialNetworks} className="footerSocial" location="footer" />
              )}
            </div>
          </div>
        </StyledFooter>
      </>
    );
  }
}

const mapStateToProps = ({ siteConfiguration }) => ({
  settings: siteConfiguration.settings,
});

const FooterComp = withTranslation()(Footer);

export default connect(mapStateToProps, null)(FooterComp);
