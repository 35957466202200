import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const props = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  overwrittenSymbol: PropTypes.string,
  smallDecimals: PropTypes.bool,
};

class NumberDisplay extends React.Component {
  static propTypes = props;

  render() {
    const {
      value,
      displayType,
      settings: { currency, featureFlags },
      generalSettings,
      overwrittenSymbol,
      smallDecimals,
      currentCurrency,
    } = this.props;
    let cur = {
      thousandSeparator: '.',
      decimalSeparator: ',',
      symbol: currentCurrency?.symbol || '$',
      decimalScale: 0,
    };

    const numberOfDecimals =
      generalSettings && generalSettings.cashBoxConfiguration
        ? generalSettings.cashBoxConfiguration.numberOfDecimals
        : 0;

    //if not use Currencies Dropdown and section "currency" is configured on json, use it.
    const useCurrenciesDropdown = featureFlags && featureFlags.showCurrenciesDropdown;
    if (!useCurrenciesDropdown) cur = { ...cur, ...currency };

    const formattedValue = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currentCurrency?.isoCode || 'ARS',
      minimumFractionDigits: numberOfDecimals,
      maximumFractionDigits: numberOfDecimals,
    }).format(value);

    const [integerPart, decimalPart] = formattedValue.split(cur.decimalSeparator);

    const currencySymbol = overwrittenSymbol || cur.symbol;

    const getSmallDecimalsPrice = () => {
      return (
        <>
          <div
            className={`${this.props.className || ''} ${
              value && value.toString().length >= 7 ? 'long-value' : 'short-value'
            }`}
          >
            <NumberFormat
              value={value}
              displayType={displayType || 'text'}
              thousandSeparator={cur.thousandSeparator}
              decimalSeparator={cur.decimalSeparator}
              prefix={currency?.showSymbolAsSuffix ? null : `${currencySymbol} `}
              suffix={currency?.showSymbolAsSuffix ? ` ${currencySymbol}` : null}
              decimalScale={numberOfDecimals || cur.decimalScale}
              fixedDecimalScale
              renderText={() => (
                <>
                  {integerPart}
                  {decimalPart && cur.decimalSeparator}
                  {decimalPart && <sup style={{ fontSize: '60%' }}>{decimalPart}</sup>}
                </>
              )}
            />
          </div>
        </>
      );
    };

    const getNormalDecimalsPrice = () => {
      return (
        <>
          <NumberFormat
            className={`${this.props.className || ''} ${
              value && value.toString().length >= 7 ? 'long-value' : ''
            }`}
            value={value}
            displayType={displayType || 'text'}
            thousandSeparator={cur.thousandSeparator}
            decimalSeparator={cur.decimalSeparator}
            prefix={currency?.showSymbolAsSuffix ? null : `${currencySymbol} `}
            suffix={currency?.showSymbolAsSuffix ? ` ${currencySymbol}` : null}
            decimalScale={numberOfDecimals || cur.decimalScale}
            fixedDecimalScale
          />
        </>
      );
    };

    return smallDecimals ? getSmallDecimalsPrice() : getNormalDecimalsPrice();
  }
}

const mapStateToProps = ({ siteConfiguration, global, state }) => ({
  settings: siteConfiguration.settings,
  generalSettings: global.generalSettings,
});

export default connect(mapStateToProps, null)(NumberDisplay);
