/* eslint-disable no-undef */
import React from 'react';
import { Title, TextInput, SelectInput, Btn, Paragraph } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyledCredomaticSection } from './CredomaticSection.styled';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from '../../../utils/translationHelper';
import * as yup from 'yup';
import { payBooking, payInfraction } from 'services/api';
import queryString from 'query-string';
import { history } from '/store';
import { BookingStatus } from '../../../utils/constants';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from '../../../utils/priceHelper';

import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
  gtag_purchase,
} from '../../../utils/googleTagsHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import PartialPayWarning from '../partialPayWarning';

var CreditCardValidator = require('card-validator');

class CredomaticSection extends BasePaymentSection {
  constructor(props) {
    const { t } = props;
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        qsamout: 0,
        bookingId: '0',
        promotionId: '',
        currencyCode: '',
        exchangeRate: 1,
      },
      formErrors: {
        cardNumber: '',
        year: '',
        month: '',
        secCode: '',
        cardholderName: '',
      },
      cardIcon: 'far fa-credit-card',
      formLoading: false,
    };

    yup.addMethod(yup.number, 'isValidCreditCard', function(creditCardNumber) {
      return this.test('test-cc-number', t('cc_rejected_bad_filled_card_number'), function(
        ccNumber,
      ) {
        return CreditCardValidator.number(ccNumber).isValid;
      });
    });

    yup.addMethod(yup.number, 'isValidSecCode', function(secCode) {
      return this.test('test-cc-number', t('cc_rejected_bad_filled_security_code'), function(
        secCode,
      ) {
        const ccObject = CreditCardValidator.number(this.parent.cardNumber);
        if (!secCode || !ccObject.card) return false;
        return secCode.toString().length === ccObject.card.code.size;
      });
    });

    this.paymentInfoFormSchema = yup.object().shape({
      cardNumber: yup
        .number()
        .isValidCreditCard()
        .required(t('inputIsRequired', { field: t('numeroTarjeta') })),
      year: yup.number().required(t('inputIsRequired', { field: t('año') })),
      month: yup.number().required(t('inputIsRequired', { field: t('mes') })),
      secCode: yup
        .number()
        .isValidSecCode()
        .required(t('inputIsRequired', { field: t('codigo') })),
      cardholderName: yup.string().required(t('inputIsRequired', { field: t('titular') })),
    });

    this.formRef = React.createRef(null);
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(15), (val, index) => index + year);
    this.months = Array.from(new Array(12), (val, index) => index + 1);
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  componentDidMount() {
    const {
      location,
      bookings,
      infractions,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      defaultCurrency,
    } = this.props;
    const parseQs = queryString.parse(location.search);

    if (this.isPayingBooking() && bookings.confirmation != null) {
      // If the currency is not the system currency and there's an exchange rate
      // use that
      if (bookings.confirmation.currency !== defaultCurrency.isoCode) {
        bookings.confirmation.balance =
          bookings.confirmation.balance * bookings.confirmation.exchangeRate;
        bookings.confirmation.currency = defaultCurrency.isoCode;
      }

      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 3, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 3, option: 'Credomatic' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
          promotionId: parseQs.promotionid || null,
          currencyCode: bookings.confirmation.currency,
          exchangeRate: 1,
        },
      }));
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          currencyCode: infraction.booking?.currency || defaultCurrency.isoCode, // TODO: Get currency in infraction object
          exchangeRate: 1,
        },
      }));
    }
  }

  getInfractionTotalToPay() {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    if (qsamount && Math.abs(infraction.amount) >= Math.abs(qsamount)) {
      return Math.abs(qsamount);
    } else {
      return infraction.amount;
    }
  }

  handleCardNumber = cardNumber => {
    const ccObject = CreditCardValidator.number(cardNumber);
    const ccIcon = this.getCreditCardIcon(ccObject.card ? ccObject.card.type : '');

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, cardNumber },
      formErrors: { ...prevState.formErrors, cardNumber: null, secCode: null },
      cardIcon: ccIcon,
    }));
  };

  getCreditCardIcon = ccType => {
    let icon = '';

    switch (ccType) {
      case 'visa':
        icon = 'fab fa-cc-visa';
        break;
      case 'american-express':
        icon = 'fab fa-cc-amex';
        break;
      case 'mastercard':
        icon = 'fab fa-cc-mastercard';
        break;
      default:
        icon = 'far fa-credit-card';
        break;
    }

    return icon;
  };

  handleSecCode = secCode => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, secCode },
      formErrors: { ...prevState.formErrors, secCode: null },
    }));
  };

  handleMonth = month => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, month },
      formErrors: { ...prevState.formErrors, month: null },
    }));
  };

  handleYear = year => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, year },
      formErrors: { ...prevState.formErrors, year: null },
    }));
  };

  handleCardholderName = cardholderName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, cardholderName },
      formErrors: { ...prevState.formErrors, cardholderName: null },
    }));
  };

  handleSubmit = e => {
    e.preventDefault();

    const paymentInfo = this.state.paymentInfo;
    const form = e.target;

    this.setState({ formLoading: true });

    this.paymentInfoFormSchema
      .validate(paymentInfo, { abortEarly: false })
      .then(valid => {
        if (valid) this.executePayment(paymentInfo);
      })
      .catch(err => {
        this.setState({ formLoading: false });
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({ ...prevState, formErrors }));
      });
  };

  executePayment = async params => {
    const {
      t,
      i18n,
      bookings,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;

    let paymentParams = this.state.paymentInfo;
    paymentParams.gatewayId = 'BAC';

    try {
      await makePayment(paymentParams);

      if (is_gtag_enabled(googleTagManager)) gtag_purchase(bookings.confirmation, settings);
      history.push(
        `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
      );
    } catch (error) {
      let errorMessage;

      if (error.response == null || error.response.data == null) {
        errorMessage = t('credomaticGenericError');
      } else if (error.response.data.code === 203) {
        errorMessage = t('credomaticAddPaymentFailed', {
          transactionId: error.response.message,
        });
      }
      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        { error: errorMessage },
      );
    }
  };

  makePayment = paymentInfo => {
    if (this.isPayingBooking()) return payBooking(paymentInfo);
    if (this.isPayingInfraction()) return payInfraction(paymentInfo);
  };

  showOrderIdentifier = () => {
    const { t, bookings, settings } = this.props;
    const confirmedOrderText = settings?.paymentConfiguration?.confirmedOrderText;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      const bookingStatus = bookings.confirmation.currentStatus;

      if ([BookingStatus.reserved, BookingStatus.confirmed].includes(bookingStatus)) {
        return (
          tt(confirmedOrderText, t, true, { booking_id }) || t('ordenConfirmada', { booking_id })
        );
      }
      return t('orden', { booking_id });
    }

    if (this.isPayingInfraction())
      return `ACTA ${this.state.paymentInfo.act?.toString().padStart(7, '0')}`;
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  getPayButtonText = () => {
    const { t } = this.props;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      return this.state.formLoading
        ? t('pagandoReserva', { booking_id })
        : t('pagarReserva', { booking_id });
    }

    if (this.isPayingInfraction()) {
      const act = this.state.paymentInfo.act?.toString().padStart(7, '0');
      return this.state.formLoading ? t('payingInfraction', { act }) : t('payInfraction', { act });
    }
  };

  render() {
    const { t, title, subtitle, currentCurrency, defaultCurrency, webCurrency } = this.props;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    return (
      <StyledCredomaticSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="images/payments/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <img
              width="280"
              height="42"
              className="mr-2"
              src="images/payments/compra-segura.jpg"
              alt={t('CompraSegura')}
            />
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              <form onSubmit={this.handleSubmit} ref={this.formRef}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('numeroTarjeta')}</span>
                      <TextInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleCardNumber}
                        value={this.state.paymentInfo.cardNumber}
                        icon={this.state.cardIcon}
                        options={{ 'data-checkout': 'cardNumber' }}
                        error={this.state.formErrors.cardNumber}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('codigo')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleSecCode}
                        value={this.state.paymentInfo.secCode}
                        options={{ 'data-checkout': 'securityCode' }}
                        error={this.state.formErrors.secCode}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('mes')}</span>
                      <SelectInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleMonth}
                        selected={this.state.paymentInfo.month}
                        error={this.state.formErrors.month}
                        props={{ 'data-checkout': 'cardExpirationMonth' }}
                        options={this.months}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <span className="label">{t('año')}</span>
                      <SelectInput
                        inputClass="form-control"
                        type="number"
                        onChange={this.handleYear}
                        error={this.state.formErrors.year}
                        selected={this.state.paymentInfo.year}
                        props={{ 'data-checkout': 'cardExpirationYear' }}
                        options={this.years}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('titular')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleCardholderName}
                        value={this.state.paymentInfo.cardholderName}
                        options={{
                          'data-checkout': 'cardholderName',
                          id: 'cardholderName',
                        }}
                        error={this.state.formErrors.cardholderName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <Btn
                      type="submit"
                      text={this.getPayButtonText()}
                      disabled={this.state.formLoading}
                      className="mt-5 credomatic-pay_button"
                    />
                  </div>
                  <div className="logo-container">
                    <span>{t('providedBy')}</span>
                    <img
                      width="151"
                      height="52"
                      className="credomatic-logo"
                      src="https://rently.azureedge.net/rently/Web/credomatic-logo.png"
                      alt="BAC Credomatic"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </StyledCredomaticSection>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, siteConfiguration, global }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});
export default connect(mapStateToProps)(withRouter(withTranslation()(CredomaticSection)));
