import {
  LOG_IN,
  LOG_OUT,
  GET_USER_BOOKINGS,
  GET_USER_INFRACTIONS,
  UPDATE_PROFILE,
  RESET_PASSWORD,
} from 'utils/types';
import {
  logIn,
  register,
  getUserProfile,
  getUserBookings,
  getUserInfractions,
  logOut,
  updateUserProfile,
  forgotPassword,
  resetPassword,
  loginWithMicrosoft,
  getMicrosoftAuthorizationUrl,
} from 'services/userService';

import { registerCompany } from 'services/userCompanyService';

export const handleRegister = ({ name, lastname, email, password }) => async dispatch => {
  try {
    await register(name, lastname, email, password);
    const { data } = await getUserProfile();

    return Promise.resolve(
      dispatch({
        type: LOG_IN,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const handleLogIn = (email, password, remember) => async dispatch => {
  try {
    if (email && password) await logIn(email, password, remember);
    const { data } = await getUserProfile();

    Promise.resolve(
      dispatch({
        type: LOG_IN,
        payload: data,
      }),
    );
    return { error: '' };
  } catch (error) {
    return { error };
  }
};

export const handleLoginWithMicrosoft = (code, state) => async dispatch => {
  try {
    await loginWithMicrosoft(code, state);
    const { data } = await getUserProfile();

    dispatch({
      type: LOG_IN,
      payload: data,
    });
  } catch (error) {
    throw error;
  }
};

export const handleGetMicrosoftLoginUrl = async () => {
  try {
    const { data } = await getMicrosoftAuthorizationUrl();
    return data;
  } catch (error) {
    throw error;
  }
};

export const handleGetProfile = () => async dispatch => {
  try {
    const { data } = await getUserProfile();

    return Promise.resolve(
      dispatch({
        type: UPDATE_PROFILE,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const handleLogOut = () => async dispatch => {
  try {
    await logOut();
    return Promise.resolve(
      dispatch({
        type: LOG_OUT,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const handleGetUserBookings = (offset, limit) => async dispatch => {
  try {
    const { data } = await getUserBookings(offset, limit);

    return Promise.resolve(
      dispatch({
        type: GET_USER_BOOKINGS,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const handleGetUserInfractions = (offset, limit) => async dispatch => {
  try {
    const { data } = await getUserInfractions(offset, limit);

    return Promise.resolve(
      dispatch({
        type: GET_USER_INFRACTIONS,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const handleUpdateUserProfile = profile => async dispatch => {
  try {
    await updateUserProfile(profile);

    return Promise.resolve(
      dispatch({
        type: UPDATE_PROFILE,
        payload: profile,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const handleRegisterCompany = ({ globalId, password }) => async dispatch => {
  try {
    var result = await registerCompany(globalId, password);
    if (!result.success) {
      return Promise.reject(result);
    }
    const { data } = await getUserProfile();

    return Promise.resolve(
      dispatch({
        type: LOG_IN,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const handleForgotPassword = ({ email }) => async dispatch => {
  try {
    var result = await forgotPassword(email);

    return Promise.resolve(
      dispatch({
        type: RESET_PASSWORD,
        payload: result,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const handleResetPassword = ({ email, password, token }) => async dispatch => {
  try {
    var result = await resetPassword(email, password, token);
    if (!result.success) {
      return Promise.reject(result);
    }
    const { data } = await getUserProfile();

    return Promise.resolve(
      dispatch({
        type: LOG_IN,
        payload: data,
      }),
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
