import style from 'styled-components';

export const StyledPageWithTitle = style.section`
  @media (max-width: ${props => props.theme.screens.md}){
    h2{
      text-align:left;
      font-size: 16px !important;
    }
  }

  h2{
    font-size: 22px;
    margin-top: 15px;
  }
  
  h1{
    font-size: 16px;
    margin-top: 0;
    @media only screen and (min-width: ${props =>
      props.theme.screens.xl}) and (max-width: ${props => props.theme.screens.xxl}){
      font-size: 16px !important;
    }

  }
`;

export const StyledPageWithTitleModern = style.section`
background:#FFF;
  h2{
    font-size: 34px !important;
    margin-top: 15px;
    color:${props => props.theme.colors.primary};
    font-weight:400
  }

  @media (max-width: 767px){
    h2{
      text-align:left;
      font-size: 25px !important;
    }
      }
      
  h1{
    font-size: 16px;
    margin-top: 0;
    @media only screen and (min-width: ${props =>
      props.theme.screens.xl}) and (max-width: ${props => props.theme.screens.xxl}){
      font-size: 16px !important;
    }

  }
`;

export const StyledBanner = style.section`
  background-image: url(${props => props.bannerImage});
  background-size: cover;
  background-position: center;
  height: ${props => props.height || '200px'};
`;

export const StyledMap = style.section`
  position: relative;
  
  .gradient {
    width: 100%;
    height: 80px;
    position: absolute;
    z-index: 2;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    bottom: 0;
  }
  .map-container {
    height: ${props => props.mapContainerHeight || '350px'};
    width: 100%;
    position: relative;
    overflow: hidden;
  }
`;
