import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { StyledRegisterForm } from './RegisterForm.styled';
import { StyledModernRegisterForm } from './RegisterForm.styled.modern';
import { fetchCountries } from '../../actions/global.actions';
import { MODERN_THEME } from 'styled/constants';
import { TextInput, Card, Btn } from 'components/';
import { isValidEmail } from 'utils/dataHelper';
import { handleRegister } from 'actions';
import { tlink } from 'utils/translationHelper';
// import { transformCountriesToSelectOptions } from 'utils/dataHelper';

function RegisterForm({
  t,
  i18n,
  settings: { theme, myAccountConfiguration, configurations },
  countries,
  isAuthenticated,
  handleRegister,
  history,
}) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [nameBlur, setNameBlur] = useState(false);
  const [lastnameBlur, setLastnameBlur] = useState(false);
  const [emailBlur, setEmailBlur] = useState(false);
  const [passwordBlur, setPasswordBlur] = useState(false);
  const [repeatPasswordBlur, setRepeatPasswordBlur] = useState(false);
  const [error, setError] = useState('');

  const getLink = path => {
    return tlink(path, t, i18n, null, configurations.langConfig);
  };

  useEffect(() => {
    const getCountries = async () => {
      if (!countries) await fetchCountries(i18n.language);
    };

    getCountries();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (myAccountConfiguration?.privateSite) {
        history.replace(history.location?.state?.referrer || getLink('/'));
      } else {
        history.replace(getLink('/my-account'));
      }
    }
  }, [isAuthenticated]);

  const StyledComponent =
    theme.template === MODERN_THEME ? StyledModernRegisterForm : StyledRegisterForm;

  const register = async e => {
    e.preventDefault();
    setError('');
    try {
      setLoading(true);
      await handleRegister({
        name,
        lastname,
        email,
        password,
      });
    } catch (error) {
      const message = error.response && error.response.data.message;
      setError(message || t('registerError'));
    } finally {
      setLoading(false);
    }
  };

  const disableRegister =
    !name ||
    !lastname ||
    !isValidEmail(email) ||
    password.length < 6 ||
    password !== repeatPassword;

  return (
    <StyledComponent>
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible className="error-alert">
          {error}
        </Alert>
      )}
      <Card>
        <Form>
          <Row>
            <Col>
              <h2 className="title">{t('myAccount.registerForFree')}</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <TextInput
                inputClass="form-control"
                className="form-control"
                placeholder={t('name')}
                value={name}
                onChange={setName}
                options={{ onBlur: () => setNameBlur(true) }}
                error={nameBlur && !name && t('emptyField')}
              />
            </Col>
            <Col md={6}>
              <TextInput
                inputClass="form-control"
                type="mail"
                className="form-control"
                placeholder={t('lastname')}
                value={lastname}
                onChange={setLastname}
                options={{ onBlur: () => setLastnameBlur(true) }}
                error={lastnameBlur && !lastname && t('emptyField')}
              />
            </Col>
          </Row>
          {/* 
          // Uncomment to add Country and Passport fields
          <Row className="mb-3">
            <Col md={6}>
              <div className="form-group">
                <SelectInput
                  inputClass="form-control form-control-select"
                  onChange={() => {}}
                  selected={''}
                  options={transformCountriesToSelectOptions(countries) || []}
                  defaultOption={t('country')}
                />
              </div>
            </Col>
            <Col md={6}>
              <TextInput
                inputClass="form-control"
                className="form-control"
                placeholder={t('myAccount.passport')}
                onChange={() => {}}
              />
            </Col>
          </Row> */}
          <Row className="mb-3">
            <Col md={12}>
              <TextInput
                inputClass="form-control"
                type="mail"
                className="form-control"
                placeholder={t('myAccount.email')}
                value={email}
                onChange={setEmail}
                options={{ onBlur: () => setEmailBlur(true) }}
                error={emailBlur && !isValidEmail(email) && t('validEmail')}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <TextInput
                inputClass="form-control"
                type="password"
                className="form-control"
                placeholder={t('myAccount.password')}
                value={password}
                onChange={setPassword}
                options={{ onBlur: () => setPasswordBlur(true) }}
                error={passwordBlur && password.length < 6 && t('passwordError')}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <TextInput
                inputClass="form-control"
                type="password"
                className="form-control"
                placeholder={t('myAccount.repeatPassword')}
                value={repeatPassword}
                onChange={setRepeatPassword}
                options={{ onBlur: () => setRepeatPasswordBlur(true) }}
                error={
                  repeatPasswordBlur &&
                  (!repeatPassword || password !== repeatPassword) &&
                  t('passwordMatch')
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Btn
                className="m-auto submit_btn"
                text={t('myAccount.register')}
                disabled={disableRegister || loading}
                loading={loading}
                iconColor="white"
                iconSize="1.2rem"
                onClick={register}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </StyledComponent>
  );
}

const mapStateToProps = ({ siteConfiguration, global, user }) => ({
  settings: siteConfiguration.settings,
  countries: global.listCountries,
  isAuthenticated: user.isAuthenticated,
});

export default connect(mapStateToProps, {
  fetchCountries,
  handleRegister,
})(withRouter(withTranslation()(RegisterForm)));
