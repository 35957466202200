import React from 'react';
import { ColumnsSection, CustomLoading } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { GetBook } from '../../../actions/bookings.actions';
import { history } from '../../../store';
import { tt, tlink } from 'utils/translationHelper';
import { BookingStatus } from '../../../utils/constants';

class BookingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    const {
      location,
      bookings: { confirmation },
      t,
      i18n,
      settings,
    } = this.props;
    const parseQs = queryString.parse(location.search);
    if (parseQs.bookingid) {
      this.setState({ loading: true });
    } else if (!confirmation) {
      this.setState({ loading: true });
      history.push(
        tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
      );
    }
  }

  componentDidMount() {
    const { location, GetBook, t, i18n, settings, isWidget } = this.props;
    const parseQs = queryString.parse(location.search);
    if (parseQs.bookingid) {
      GetBook(parseQs.bookingid)
        .then(x => {
          if (x.payload.currentStatus === BookingStatus.canceled) {
            history.push(
              tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
            );
          } else {
            if (x.payload.balance < 0) {
              this.setState({ loading: false });
            } else {
              history.push(
                tlink('__Routes.bookingPaid', t, i18n, null, settings.configurations.langConfig),
              );
            }
          }
        })
        .catch(error => {
          history.push(
            tlink('__Routes.getBookFailed', t, i18n, null, settings.configurations.langConfig),
          );
        });
    }

    if (isWidget) {
      window.top.postMessage(
        { height: document.body.scrollHeight, width: document.body.scrollWidth },
        '*',
      );
    }
  }

  render() {
    const { loadingMessage, loadingGif, background } = this.props;

    if (this.state.loading === true) {
      return (
        <CustomLoading
          loadingMessage={loadingMessage}
          loadingGif={loadingGif}
          background={background}
        ></CustomLoading>
      );
    } else {
      return <ColumnsSection {...this.props}></ColumnsSection>;
    }
  }
}

const mapStateToProps = ({ bookings, siteConfiguration }) => ({
  bookings: bookings,
  settings: siteConfiguration.settings,
});
export default connect(mapStateToProps, { GetBook })(withRouter(withTranslation()(BookingSection)));
