import React from 'react';
import { props } from './BasePage.props';
import { CompositeComponent } from 'components/';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { tlink } from 'utils/translationHelper';
import { getLanguagePath } from '../../../utils/translationHelper';
import parse from 'html-react-parser';

class BasePage extends CompositeComponent {
  static propTypes = props;

  componentDidMount() {
    const {
      settings: { configurations },
      theme,
    } = this.props;

    document.title = this.props.metaTitle
      ? `${this.tt(this.props.metaTitle)}`
      : `${this.tt(this.props.subTitle || this.props.title)} | ${configurations.title}`;

    let parent = document.getElementById('app-container');

    if (this.props.backgroundColor) {
      parent.style.backgroundColor = this.props.backgroundColor;
    } else {
      parent.style.backgroundColor = theme.colors.bg;
    }
  }

  getRobotsTag() {
    const { robots } = this.props;
    if (robots) return <meta name="robots" content={robots}></meta>;
    else
      return (
        <meta
          name="robots"
          content={window.location.origin.indexOf('rentlynetwork.com') >= 0 ? 'noindex' : 'index'}
        ></meta>
      );
  }

  getOgTags() {
    const { ogTags, t } = this.props;
    if (ogTags) {
      return (
        <Helmet>
          {ogTags.title && <meta property="og:title" content={this.tt(ogTags.title, t)}></meta>}
          {ogTags.description && (
            <meta property="og:description" content={this.tt(ogTags.description, t)}></meta>
          )}
          {ogTags.image && <meta property="og:image" content={this.tt(ogTags.image, t)}></meta>}
          {ogTags.site_name && (
            <meta property="og:site_name" content={this.tt(ogTags.site_name, t)}></meta>
          )}
          {ogTags.url && <meta property="og:url" content={this.tt(ogTags.url, t)}></meta>}
          {ogTags.type && <meta property="og:type" content={this.tt(ogTags.url, t)}></meta>}
          {ogTags.locale && <meta property="og:locale" content={this.tt(ogTags.locale, t)}></meta>}
          {ogTags.video && <meta property="og:video" content={this.tt(ogTags.video, t)}></meta>}
          {ogTags.audio && <meta property="og:audio" content={this.tt(ogTags.audio, t)}></meta>}
          {ogTags.published_time && (
            <meta property="og:published_time" content={this.tt(ogTags.published_time, t)}></meta>
          )}

          {/* Facebook OG tags */}
          {ogTags.facebook?.fb_app_id && (
            <meta property="fb:app_id" content={this.tt(ogTags.facebook.fb_app_id, t)}></meta>
          )}
          {ogTags.facebook?.fb_admins && (
            <meta property="fb:admins" content={this.tt(ogTags.facebook.fb_admins, t)}></meta>
          )}
          {ogTags.facebook?.fb_page_id && (
            <meta property="fb:page_id" content={this.tt(ogTags.facebook.fb_page_id, t)}></meta>
          )}
          {ogTags.facebook?.fb_profile_id && (
            <meta
              property="fb:profile_id"
              content={this.tt(ogTags.facebook.fb_profile_id, t)}
            ></meta>
          )}
          {ogTags.facebook?.fb_publisher && (
            <meta property="fb:publisher" content={this.tt(ogTags.facebook.fb_publisher, t)}></meta>
          )}
          {ogTags.facebook?.fb_section && (
            <meta property="fb:section" content={this.tt(ogTags.facebook.fb_section, t)}></meta>
          )}
          {ogTags.facebook?.fb_tag && (
            <meta property="fb:tag" content={this.tt(ogTags.facebook.fb_tag, t)}></meta>
          )}

          {/* Twitter OG tags */}
          {ogTags.twitter?.twitter_card && (
            <meta property="twitter:card" content={this.tt(ogTags.twitter.twitter_card, t)}></meta>
          )}
          {ogTags.twitter?.twitter_site && (
            <meta property="twitter:site" content={this.tt(ogTags.twitter.twitter_site, t)}></meta>
          )}
          {ogTags.twitter?.twitter_creator && (
            <meta
              property="twitter:creator"
              content={this.tt(ogTags.twitter.twitter_creator, t)}
            ></meta>
          )}
          {ogTags.twitter?.twitter_title ? (
            <meta
              property="twitter:title"
              content={this.tt(ogTags.twitter.twitter_title, t)}
            ></meta>
          ) : ogTags.title ? (
            <meta property="twitter:title" content={this.tt(ogTags.title, t)}></meta>
          ) : null}
          {ogTags.twitter?.twitter_description ? (
            <meta
              property="twitter:description"
              content={this.tt(ogTags.twitter.twitter_description, t)}
            ></meta>
          ) : ogTags.description ? (
            <meta property="twitter:description" content={this.tt(ogTags.description, t)}></meta>
          ) : null}

          {ogTags.twitter?.twitter_image ? (
            <meta
              property="twitter:image"
              content={this.tt(ogTags.twitter.twitter_image, t)}
            ></meta>
          ) : ogTags.image ? (
            <meta property="twitter:image" content={this.tt(ogTags.image, t)}></meta>
          ) : null}

          {ogTags.twitter?.twitter_image_alt && (
            <meta
              property="twitter:image:alt"
              content={this.tt(ogTags.twitter.twitter_image_alt, t)}
            ></meta>
          )}

          {/* LinkedIn+ OG tags */}
          {ogTags.linkedin?.linkedin_title ? (
            <meta
              property="linkedin:title"
              content={this.tt(ogTags.linkedin.linkedin_title, t)}
            ></meta>
          ) : ogTags.title ? (
            <meta property="linkedin:title" content={this.tt(ogTags.title, t)}></meta>
          ) : null}

          {ogTags.linkedin?.linkedin_description ? (
            <meta
              property="linkedin:description"
              content={this.tt(ogTags.linkedin.linkedin_description, t)}
            ></meta>
          ) : ogTags.description ? (
            <meta property="linkedin:description" content={this.tt(ogTags.description, t)}></meta>
          ) : null}
          {ogTags.linkedin?.linkedin_image ? (
            <meta
              property="linkedin:image"
              content={this.tt(ogTags.linkedin.linkedin_image, t)}
            ></meta>
          ) : ogTags.image ? (
            <meta property="linkedin:image" content={this.tt(ogTags.image, t)}></meta>
          ) : null}

          {ogTags.linkedin?.linkedin_image_alt && (
            <meta
              property="linkedin:image:alt"
              content={this.tt(ogTags.linkedin.linkedin_image_alt, t)}
            ></meta>
          )}
          {ogTags.linkedin?.linkedin_url ? (
            <meta property="linkedin:url" content={this.tt(ogTags.linkedin.linkedin_url, t)}></meta>
          ) : ogTags.url ? (
            <meta property="linkedin:url" content={this.tt(ogTags.url, t)}></meta>
          ) : null}
          {ogTags.linkedin?.linkedin_author && (
            <meta
              property="linkedin:author"
              content={this.tt(ogTags.linkedin.linkedin_author, t)}
            ></meta>
          )}
          {ogTags.linkedin?.linkedin_publisher && (
            <meta
              property="linkedin:publisher"
              content={this.tt(ogTags.linkedin.linkedin_publisher, t)}
            ></meta>
          )}
          {ogTags.linkedin?.linkedin_section && (
            <meta
              property="linkedin:section"
              content={this.tt(ogTags.linkedin.linkedin_section, t)}
            ></meta>
          )}
          {ogTags.linkedin?.linkedin_article_tag && (
            <meta
              property="linkedin:article:tag"
              content={this.tt(ogTags.linkedin.linkedin_article_tag, t)}
            ></meta>
          )}
          {ogTags.linkedin?.linkedin_article_published_time ? (
            <meta
              property="linkedin:article:published_time"
              content={this.tt(ogTags.linkedin.linkedin_article_published_time, t)}
            ></meta>
          ) : ogTags.published_time ? (
            <meta
              property="linkedin:article:published_time"
              content={this.tt(ogTags.published_time, t)}
            ></meta>
          ) : null}
          {ogTags.linkedin?.linkedin_article_author && (
            <meta
              property="linkedin:article:author"
              content={this.tt(ogTags.linkedin.linkedin_article_author, t)}
            ></meta>
          )}
          {ogTags.linkedin?.linkedin_article_section && (
            <meta
              property="linkedin:article:section"
              content={this.tt(ogTags.linkedin.linkedin_article_section, t)}
            ></meta>
          )}
          {ogTags.linkedin?.linkedin_article_publisher && (
            <meta
              property="linkedin:article:publisher"
              content={this.tt(ogTags.linkedin.linkedin_article_publisher, t)}
            ></meta>
          )}

          {/* Pinterest OG tags */}
          {ogTags.pinterest?.pinterest_article && (
            <meta
              property="pinterest:article"
              content={this.tt(ogTags.pinterest.pinterest_article, t)}
            ></meta>
          )}
          {ogTags.pinterest?.pinterest_article_author && (
            <meta
              property="pinterest:article:author"
              content={this.tt(ogTags.pinterest.pinterest_article_author, t)}
            ></meta>
          )}
          {ogTags.pinterest?.pinterest_article_section && (
            <meta
              property="pinterest:article:section"
              content={this.tt(ogTags.pinterest.pinterest_article_section, t)}
            ></meta>
          )}
          {ogTags.pinterest?.pinterest_article_tag && (
            <meta
              property="pinterest:article:tag"
              content={this.tt(ogTags.pinterest.pinterest_article_tag, t)}
            ></meta>
          )}
          {ogTags.pinterest?.pinterest_article_published_time ? (
            <meta
              property="pinterest:article:published_time"
              content={this.tt(ogTags.pinterest.pinterest_article_published_time, t)}
            ></meta>
          ) : ogTags.published_time ? (
            <meta
              property="pinterest:article:published_time"
              content={this.tt(ogTags.published_time, t)}
            ></meta>
          ) : null}
          {ogTags.pinterest?.pinterest_article_publisher && (
            <meta
              property="pinterest:article:publisher"
              content={this.tt(ogTags.pinterest.pinterest_article_publisher, t)}
            ></meta>
          )}
          {ogTags.pinterest?.pinterest_article_section && (
            <meta
              property="pinterest:article:section"
              content={this.tt(ogTags.pinterest.pinterest_article_section, t)}
            ></meta>
          )}

          {/* TikTok OG tags */}
          {ogTags.tiktok?.tiktok_title ? (
            <meta property="tiktok:title" content={this.tt(ogTags.tiktok.tiktok_title, t)}></meta>
          ) : ogTags.title ? (
            <meta property="tiktok:title" content={this.tt(ogTags.title, t)}></meta>
          ) : null}
          {ogTags.tiktok?.tiktok_description ? (
            <meta
              property="tiktok:description"
              content={this.tt(ogTags.tiktok.tiktok_description, t)}
            ></meta>
          ) : ogTags.description ? (
            <meta property="tiktok:description" content={this.tt(ogTags.description, t)}></meta>
          ) : null}
          {ogTags.tiktok?.tiktok_image ? (
            <meta property="tiktok:image" content={this.tt(ogTags.tiktok.tiktok_image, t)}></meta>
          ) : ogTags.image ? (
            <meta property="tiktok:image" content={this.tt(ogTags.image, t)}></meta>
          ) : null}
          {ogTags.tiktok?.tiktok_image_alt && (
            <meta
              property="tiktok:image:alt"
              content={this.tt(ogTags.tiktok.tiktok_image_alt, t)}
            ></meta>
          )}
          {ogTags.tiktok?.tiktok_url ? (
            <meta property="tiktok:url" content={this.tt(ogTags.tiktok.tiktok_url, t)}></meta>
          ) : ogTags.url ? (
            <meta property="tiktok:url" content={this.tt(ogTags.url, t)}></meta>
          ) : null}
          {ogTags.tiktok?.tiktok_author && (
            <meta property="tiktok:author" content={this.tt(ogTags.tiktok.tiktok_author, t)}></meta>
          )}
          {ogTags.tiktok?.tiktok_publisher && (
            <meta
              property="tiktok:publisher"
              content={this.tt(ogTags.tiktok.tiktok_publisher, t)}
            ></meta>
          )}
          {ogTags.tiktok?.tiktok_section && (
            <meta
              property="tiktok:section"
              content={this.tt(ogTags.tiktok.tiktok_section, t)}
            ></meta>
          )}
          {ogTags.tiktok?.tiktok_article_tag && (
            <meta
              property="tiktok:article:tag"
              content={this.tt(ogTags.tiktok.tiktok_article_tag, t)}
            ></meta>
          )}
        </Helmet>
      );
    }
  }

  getSchemaTags() {
    const { schemaTags, t } = this.props;
    if (schemaTags) {
      const stringSchemaTags = this.tt(schemaTags, t);
      return (
        <Helmet>
          <script type="application/ld+json">{`${stringSchemaTags}`}</script>
        </Helmet>
      );
    }
  }

  render() {
    const {
      description,
      subTitle,
      listLanguages,
      t,
      i18n,
      path,
      customHeadContent,
      settings,
    } = this.props;

    let pathForCanonical = path;
    if (Array.isArray(path)) {
      pathForCanonical = path[0];
    }

    const defaultLang = this.props.settings.configurations.defaultLang;

    return (
      <>
        <Helmet>
          {(description || subTitle) && (
            <meta name="description" content={this.tt(description || subTitle)} />
          )}

          <link
            rel="canonical"
            href={`${window.location.origin}${tlink(
              pathForCanonical,
              t,
              i18n,
              null,
              settings.configurations.langConfig,
            )}`}
          />

          {listLanguages &&
            listLanguages.map((x, i) => (
              <link
                key={i}
                rel="alternate"
                hreflang={getLanguagePath(settings.configurations.langConfig, x)}
                href={`${window.location.origin}${tlink(
                  pathForCanonical,
                  t,
                  i18n,
                  x,
                  settings.configurations.langConfig,
                )}`}
              />
            ))}

          <link
            rel="alternate"
            hreflang="x-default"
            href={`${window.location.origin}${tlink(
              pathForCanonical,
              t,
              i18n,
              defaultLang,
              settings.configurations.langConfig,
            )}`}
          />

          {customHeadContent && parse(customHeadContent)}

          {this.getRobotsTag()}

          {this.renderHeaders()}
        </Helmet>

        {this.getOgTags()}

        {this.getSchemaTags()}

        {this.renderChilds()}
      </>
    );
  }
}

const mapStateToProps = ({ siteConfiguration, global }) => ({
  settings: siteConfiguration.settings,
  sharedComponents: siteConfiguration.settings.sharedComponents,
  listLanguages: global.listLanguages,
});

export default connect(mapStateToProps)(withTheme(withTranslation()(withRouter(BasePage))));
