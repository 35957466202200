import React from 'react';
import { props, defaultProps } from './CheckoutMobileDetails.props';
import { StyledMobileDetails } from './CheckoutMobileDetails.styled.js';
import { NoBookingDivStyled } from '../CheckoutDetails/CheckoutDetails.styled.js';
import { Title, NumberDisplay, Btn, Paragraph } from 'components/';
import { withTranslation } from 'react-i18next';
import { tlink } from 'utils/translationHelper';
import { withRouter } from 'react-router-dom';
import { tt } from 'utils/translationHelper';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { getPriceWithCommission } from 'utils/dataHelper';
import { StyledMobileDetailsModern } from './CheckoutMobileDetails.styled.modern';
import { MODERN_THEME } from 'styled/constants';
import { sumBy } from 'lodash';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';

const PriceComponent = ({ name, price }) => {
  return (
    <li>
      <i className="fas fa-plus" /> <span className="item-name">{name} </span>{' '}
      <span className="item-price">
        <NumberDisplay value={price} />
      </span>
    </li>
  );
};

class CheckoutMobileDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        name: null,
        email: null,
        phone: null,
        address: null,
        id: null,
        tcAccept: null,
        paymentMethod: null,
      },
      formLoading: false,
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  getBookingButtonText() {
    const { payBookingButtonText, bookBookingButtonText, t, onlinePayment } = this.props;

    if (!onlinePayment) return bookBookingButtonText || t('bookBooking');
    else return payBookingButtonText || t('payBooking');
  }

  book = userInfo => {
    const { booking, book, bookingParams, t, i18n, onlinePayment, history, settings } = this.props;

    this.setState({ formLoading: true });
    let state = this.state;
    let isPaymentsConfig = settings.paymentConfiguration;

    book(booking, userInfo, bookingParams, false, i18n.language)
      .then(res => {
        if (onlinePayment) {
          if (isPaymentsConfig) {
            history.push(
              tlink('__Routes.onlinePayment', t, i18n, null, settings.configurations.langConfig),
              { paymentMethod: state.userInfo.paymentMethodObj.component },
            );
          } else {
            history.push(
              tlink('__Routes.onlinePayment', t, i18n, null, settings.configurations.langConfig),
            );
          }
          window.scrollTo(0, 0);
        } else {
          history.push(
            tlink('__Routes.bookSuccess', t, i18n, null, settings.configurations.langConfig),
          );
        }
      })
      .catch(error => {
        history.push(
          tlink('__Routes.bookFailed', t, i18n, null, settings.configurations.langConfig),
        );
      });
  };

  showBookingLenght = booking => {
    const {
      t,
      diasDeAlquiler,
      settings: { configurations },
    } = this.props;
    const bookingLength = Number.isInteger(booking.totalDays)
      ? booking.totalDays
      : booking.totalDaysString;

    const bookingText = diasDeAlquiler
      ? `Por ${bookingLength} ${diasDeAlquiler}`
      : t('forDaysOfRent', { totalDays: bookingLength });

    const openRateText = configurations.openRate ? ` ${t('from').toLowerCase()}` : '';

    return bookingText + openRateText;
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledMobileDetailsModern;
    }
    return StyledMobileDetails;
  }

  getDailyFinalPrice = booking => {
    if (!booking) return null;
    return getPriceWithCommission(booking.price) / booking.totalDays;
  };

  getPriceItemsContent = () => {
    const {
      searchCars: {
        checkout: { booking },
      },
      settings: { configurations, paymentConfiguration },
      t,
      currentCurrency,
      defaultCurrency,
      webCurrency,
    } = this.props;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const getDescription = item => {
      if (item.isBookingPrice && configurations.openRate) {
        return (
          <>
            {t('openRatePriceItem', { days: item.quantity })}
            (
            <NumberDisplay
              value={
                applyExchangeRate
                  ? getPriceWithExchangeRate(
                      item.unitPrice,
                      currentCurrency,
                      defaultCurrency,
                      webCurrency,
                    )
                  : item.unitPrice
              }
            />{' '}
            {t('xDay')})
          </>
        );
      }
      return item.description;
    };

    if (paymentConfiguration && paymentConfiguration.commissionPercentage > 0) {
      const bookingPriceItems = booking.priceItems.filter(i => i.isBookingPrice);
      const extraPriceItems = booking.priceItems.filter(i => !i.isBookingPrice);

      const commissionExtra = (paymentConfiguration.commissionPercentage * booking.price) / 100;

      const bookingPriceItemsAmount = sumBy(bookingPriceItems, 'price') + commissionExtra;

      return (
        <>
          <PriceComponent
            name={`${t('alquilerPor')} ${booking.totalDaysString}`}
            price={
              applyExchangeRate
                ? getPriceWithExchangeRate(
                    bookingPriceItemsAmount,
                    currentCurrency,
                    defaultCurrency,
                    webCurrency,
                  )
                : bookingPriceItemsAmount
            }
          />
          {extraPriceItems.map((item, i) => {
            return (
              <PriceComponent
                key={i}
                name={getDescription(item)}
                price={
                  applyExchangeRate
                    ? getPriceWithExchangeRate(
                        item.price,
                        currentCurrency,
                        defaultCurrency,
                        webCurrency,
                      )
                    : item.price
                }
              />
            );
          })}
        </>
      );
    }

    return booking.priceItems.map((item, i) => {
      return (
        <PriceComponent
          key={i}
          name={getDescription(item)}
          price={
            applyExchangeRate
              ? getPriceWithExchangeRate(item.price, currentCurrency, defaultCurrency, webCurrency)
              : item.price
          }
        />
      );
    });
  };

  render() {
    const {
      searchCars: {
        checkout: { booking, isLoading },
        params: { commercialAgreementCode },
      },
      t,
      bgColor,
      settings: { featureFlags, paymentConfiguration },
      color,
      weight,
      size,
      popUpErrorShow,
      text,
      disabled,
      openModal,
      closeModal,
      modalState,
      settings: { configurations },
      currentCurrency,
      defaultCurrency,
      webCurrency,
      chargedMsg,
      chargedMsg2,
    } = this.props;

    if (isLoading) {
      return <NoBookingDivStyled />;
    }

    if (booking == null) {
      return null;
    }

    const StyledMobileDetails = this.getTemplateStyledComponent();

    const dailyPrice = featureFlags.showDailyFinalPrice
      ? this.getDailyFinalPrice(booking)
      : getPriceWithCommission(booking.averageDayPrice, paymentConfiguration);

    const totalPrice = getPriceWithCommission(booking.price, paymentConfiguration);

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    return (
      <StyledMobileDetails
        className="col-xs-12"
        bgColor={bgColor}
        color={color}
        weight={weight}
        size={size}
      >
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-3 more-info text-right">
              <button className="button" onClick={openModal}>
                <i className="fa fa-info-circle fa-lg"></i>
              </button>
              <Popup open={modalState} modal closeOnDocumentClick onClose={closeModal}>
                <div className="modal">
                  <div className="header">
                    <Title
                      type="h4"
                      className="mb-0"
                      text={t('paymentDetail')}
                      weight="700"
                      fontSize={20}
                      italic={false}
                      noMedia
                    />
                    <a className="close" onClick={closeModal}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div className="content">
                    <div className="payment-details">
                      <ul className="list-unstyled">
                        {this.getPriceItemsContent()}

                        {commercialAgreementCode && (
                          <li>
                            <i className="fas fa-plus" />{' '}
                            <span className="item-name">{t('commercialAgreement')} </span>{' '}
                            <span className="item-price">{commercialAgreementCode}</span>
                          </li>
                        )}
                      </ul>
                    </div>
                    {featureFlags.showFranchiseDetails && (
                      <>
                        <hr />
                        <div className="payment-details">
                          <ul className="list-unstyled">
                            {featureFlags.showFranchiseDamages && (
                              <li>
                                <i className="fas fa-plus" />{' '}
                                <span className="item-name">
                                  {t('franchises.franchiseDamages')}{' '}
                                </span>{' '}
                                <span className="item-price">
                                  <NumberDisplay value={booking.franchiseDamage || 0} />
                                </span>
                              </li>
                            )}
                            {featureFlags.showFranchiseRollover && (
                              <li>
                                <i className="fas fa-plus" />{' '}
                                <span className="item-name">
                                  {t('franchises.franchiseRollover')}{' '}
                                </span>{' '}
                                <span className="item-price">
                                  <NumberDisplay value={booking.franchiseRollover || 0} />
                                </span>
                              </li>
                            )}
                            {featureFlags.showFranchiseTheft && (
                              <li>
                                <i className="fas fa-plus" />{' '}
                                <span className="item-name">{t('franchises.franchiseTheft')} </span>{' '}
                                <span className="item-price">
                                  <NumberDisplay value={booking.franchiseTheft || 0} />
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                    {showExchangeMsg && (
                      <Paragraph className="col-12 text-left popupChargedMsg" color={'red'}>
                        <strong>{`${tt(chargedMsg, t) || t('chargedMsg')} ${
                          currentCurrency?.isoCode
                        } ${tt(chargedMsg2, t) || t('chargedMsg2')} ${
                          currentCurrency?.exchangeRate
                        }`}</strong>
                      </Paragraph>
                    )}
                    <div className="payment mt-3 mb-3">
                      <div className="row">
                        <div className="col-7">
                          <Title type="h3" className="total-name mb-0" text={t('totalToPay')} />
                          <p className="days">
                            {t('forDaysOfRent', {
                              totalDays: booking.totalDays,
                            })}{' '}
                            {configurations.openRate && t('from').toLowerCase()}
                          </p>
                        </div>
                        <div className="col-5 text-right">
                          <p className="total-price mb-0">
                            <NumberDisplay
                              value={
                                applyExchangeRate
                                  ? getPriceWithExchangeRate(
                                      totalPrice,
                                      currentCurrency,
                                      defaultCurrency,
                                      webCurrency,
                                    )
                                  : totalPrice
                              }
                              currentCurrency={currentCurrency}
                            />
                          </p>
                          <p className="diario">
                            {t('dailyPrice')}{' '}
                            <span>
                              <NumberDisplay
                                value={
                                  applyExchangeRate
                                    ? getPriceWithExchangeRate(
                                        dailyPrice,
                                        currentCurrency,
                                        defaultCurrency,
                                        webCurrency,
                                      )
                                    : dailyPrice
                                }
                                currentCurrency={currentCurrency}
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Btn
                        form="bookingUserDataForm"
                        type="submit"
                        text={text}
                        disabled={disabled}
                        className="user-data-form_btn mt-0 w-100"
                      />

                      {popUpErrorShow && (
                        <div className="complete-info text-center mt-2 mb-2">
                          <a onClick={closeModal}>
                            <i className="fas fa-exclamation-triangle mr-1"></i>{' '}
                            {t('completeCheckoutInfo')}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Popup>
            </div>

            <div className="col-5 text-right total-block">
              <p className="days">{this.showBookingLenght(booking)}</p>
              <p className="total-price mb-3">
                <NumberDisplay
                  value={
                    applyExchangeRate
                      ? getPriceWithExchangeRate(
                          totalPrice,
                          currentCurrency,
                          defaultCurrency,
                          webCurrency,
                        )
                      : totalPrice
                  }
                />
              </p>
            </div>
            <div className="col-4 payment-button">
              <Btn
                form="bookingUserDataForm"
                type="submit"
                text={text}
                disabled={disabled}
                className="mt-0"
              />
            </div>
          </div>
          {showExchangeMsg && (
            <Paragraph className="col-12 text-left total-block" color={'red'}>
              <strong>{`${tt(chargedMsg, t) || t('chargedMsg')} ${currentCurrency?.isoCode} ${tt(
                chargedMsg2,
                t,
              ) || t('chargedMsg2')} ${currentCurrency?.exchangeRate}`}</strong>
            </Paragraph>
          )}
        </div>
      </StyledMobileDetails>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});

CheckoutMobileDetails.propTypes = props;
CheckoutMobileDetails.defaultProps = defaultProps;

const CheckoutMobileDetailsComp = withTranslation()(withRouter(CheckoutMobileDetails));

export default connect(mapStateToProps)(CheckoutMobileDetailsComp);
