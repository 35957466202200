import Fetcher from 'utils/fetcher';

const api = new Fetcher();
const apiUrl = process.env.REACT_APP_BACKEND_API;

export const fetchInitialSettings = async (version = null, isdraft = false) => {
  if (version != null) {
    return api.get(`${apiUrl}/customization/history/${version}?isdraft=${isdraft}`, true);
  }

  return api.get(`${apiUrl}/customization/SiteConfiguration`);
};

export const listCategories = async (language = '') =>
  api.get(`${apiUrl}/data/categories?language=${language}`);

export const listPlaces = async (language = '') =>
  api.get(`${apiUrl}/data/places?language=${language}`);

export const listCountries = async (language = '') =>
  api.get(`${apiUrl}/data/countries?language=${language}`);

export const listStates = async () => api.get(`${apiUrl}/data/states`);

export const listCities = async (countryIsoCode = '') =>
  api.get(`${apiUrl}/data/cities?countryIsoCode=${countryIsoCode}`);

export const listPromotions = async (language = '') =>
  api.get(`${apiUrl}/data/promotions?language=${language}`);

export const listAdditionals = async (rentlyEndpointName = '', language = '') =>
  api.get(`${apiUrl}/data/additionals?endpoint=${rentlyEndpointName}&language=${language}`);

export const listHolidays = async () => api.get(`${apiUrl}/data/holidays`);

export const listSchedules = async () => api.get(`${apiUrl}/data/attentionschedule`);

export const listLanguages = async () => api.get(`${apiUrl}/data/languages`);

export const cancelBooking = async (branchOffice, bookingId, lastName) =>
  api.remove(
    `${apiUrl}/booking?branchOffice=${branchOffice}&bookingId=${bookingId}&lastname=${lastName}`,
  );

export const fetchCars = async (
  from,
  to,
  pickUpId,
  pickUpEndpoint,
  dropOffId,
  dropOffEndpoint,
  language = '',
  ilimitedKm,
  showFinalPrice = false,
  onlyFullAvailability,
  promotions,
  globalId,
  driverAge,
  commercialAgreementCode,
) => {
  let promotionsQS = '';

  if (promotions && typeof promotions === 'object' && promotions.length > 0) {
    for (let i = 0; i < promotions.length; i++) {
      const promo = promotions[i];
      promotionsQS += `promotions=${promo}&`;
    }
  } else {
    promotionsQS += `promotions=${promotions}`;
  }

  const customerId = globalId ? `&customer.globalid=${globalId}` : '';
  const customerAge = driverAge ? `&driverAge=${driverAge}` : '';
  const caCode = commercialAgreementCode
    ? `&commercialAgreementCode=${commercialAgreementCode}`
    : '';
  const agencyGuid = globalId ? `&agencyGuid=${globalId}` : '';

  return api.get(
    `${apiUrl}/booking/search?From=${from}&to=${to}&fromPlace=${pickUpId}&PickupEndpoint=${pickUpEndpoint}&toPlace=${dropOffId}&returnendpoint=${dropOffEndpoint}&language=${language}&IlimitedKm=${ilimitedKm}&showFinalPrice=${showFinalPrice}&onlyFullAvailability=${onlyFullAvailability}&${promotionsQS}${customerId}${customerAge}${caCode}${agencyGuid}`,
  );
};

export const fetchPrice = async (
  language,
  additionals,
  from,
  to,
  pickUpId,
  pickUpEndpoint,
  dropOffId,
  modelId,
  ilimitedKm,
  promotion,
  globalId,
  driverAge,
  commercialAgreementCode,
) => {
  let serializedAdditionals = '';
  for (let i = 0; i < additionals.length; i++) {
    const additional = additionals[i];
    serializedAdditionals += `additionals[${i}].Additional.Id=${additional.id}&additionals[${i}].Quantity=${additional.number}&`;
  }

  const customerId = globalId ? `&customer.globalid=${globalId}` : '';
  const customerAge = driverAge ? `&driverAge=${driverAge}` : '';
  const caCode = commercialAgreementCode
    ? `&commercialAgreementCode=${commercialAgreementCode}`
    : '';
  const agencyGuid = globalId ? `&agencyGuid=${globalId}` : '';

  return await api.get(
    `${apiUrl}/booking/price?From=${from}&to=${to}&fromPlace=${pickUpId}&RentlyEndpointName=${pickUpEndpoint}&toPlace=${dropOffId}&modelid=${modelId}&${serializedAdditionals}&IlimitedKm=${ilimitedKm}&promotion=${promotion}&language=${language}${customerId}${customerAge}${caCode}${agencyGuid}`,
  );
};

export const getBooking = async (rentlyEndpointName, bookingId) =>
  api.get(`${apiUrl}/booking/book?rentlyEndpointName=${rentlyEndpointName}&bookingId=${bookingId}`);

export const getBookingWithPromotion = async (rentlyEndpointName, bookingId, promotionId) =>
  api.get(
    `${apiUrl}/booking/book?rentlyEndpointName=${rentlyEndpointName}&bookingId=${bookingId}&promotionId=${promotionId}`,
  );

export const createBooking = async booking => api.post(`${apiUrl}/booking/book`, booking);

export const payBooking = async payInfo => {
  const roundPayInfo = { ...payInfo };
  roundPayInfo.price = parseFloat((Math.round(roundPayInfo.price * 100) / 100).toFixed(2));
  return api.post(`${apiUrl}/booking/pay`, roundPayInfo);
};

export const confirmBooking = async confirmInfo =>
  api.post(`${apiUrl}/booking/confirm`, confirmInfo);

export const payInfraction = async payInfo => api.post(`${apiUrl}/infraction/pay`, payInfo);

export const cancelReservation = async reservation =>
  api.post(`${apiUrl}/booking/cancel`, reservation);

export const sendContact = async contact => api.post(`${apiUrl}/contacts/contact`, contact);

export const sendCompanyContact = async contact =>
  api.post(`${apiUrl}/contacts/company-contact`, contact);

export const listCurrencies = async () => api.get(`${apiUrl}/data/currencies`);

export const listLanguagesInfo = async () => api.get(`${apiUrl}/data/languages-info`);

export const getBookingsConfiguration = async () =>
  api.get(`${apiUrl}/data/bookings-configuration`);

export const getTransBankUri = async (bookingId, price) =>
  api.get(`${apiUrl}/booking/gettransbankuri?bookingId=${bookingId}&price=${price}`);

export const getBookingAdditionals = async (params, booking, language, globalId) => {
  let requestObject = {
    rentlyEndpointName: params.pickUpEndpoint,
    from: booking.fromDate,
    to: booking.toDate,
    fromPlace: params.pickUpId,
    toPlace: params.dropOffId,
    ilimitedKm: params.ilimitedKm,
    modelId: booking.car.model.id,
    categoryId: booking.category.id,
    promotion: booking.promotion !== undefined ? booking.promotion : '',
    language: language,
  };

  if (globalId) {
    requestObject = { ...requestObject, 'customer.globalid': globalId };
  }

  const url =
    `${apiUrl}/booking/additionals-price?` + new URLSearchParams(requestObject).toString();
  return api.get(url);
};

export const getValidPromotionCode = async (rentlyEndpointName, promotionCode) =>
  api.get(
    `${apiUrl}/booking/validatepromotion?rentlyEndpointName=${rentlyEndpointName}&promotionCode=${promotionCode}`,
  );

export const getInfraction = async (customerId, infractionId) =>
  api.get(`${apiUrl}/infraction/infraction?customerId=${customerId}&infractionId=${infractionId}`);

export const listDocumentTypes = async (language = '') =>
  api.get(`${apiUrl}/data/document-types?language=${language}`);

export const listTaxPayerTypes = async (language = '') =>
  api.get(`${apiUrl}/data/taxPayerTypes?language=${language}`);

export const getGatewayInformation = async (branchOfficeId, gatewayId) =>
  api.get(
    `${apiUrl}/booking/getgatewayinformation?branchOfficeId=${branchOfficeId}&gatewayId=${gatewayId}`,
  );

export const listWebFiles = async () => api.get(`${apiUrl}/data/listWebFiles`);

export const uploadWebFiles = async formData => {
  return api.post(`${apiUrl}/data/addWebFile`, formData);
};

export const getBookingAttachments = async (bookingId, category) => {
  return await api.get(`${apiUrl}/booking/attachments?bookingId=${bookingId}&category=${category}`);
};

export const attachBookingFiles = async (bookingId, files) => {
  return await api.post(`${apiUrl}/booking/attachments?bookingId=${bookingId}`, files);
};

export const getGeneralSettings = async () => api.get(`${apiUrl}/data/general-settings`);

export const getCecabankTransUri = async (branchOfficeId, bookingId, price, currencyCode) =>
  api.get(
    `${apiUrl}/booking/getcecabanktransuri?branchOfficeId=${branchOfficeId}&bookingId=${bookingId}&price=${price}&currencyCode=${currencyCode}`,
  );

export const stripeCreatePaymentIntent = async (
  branchOfficeId,
  amount,
  currencyCode,
  bookingId,
  customerId,
  customerName,
  customerEmail,
) => {
  return await api.post(
    `${apiUrl}/booking/stripeCreatePaymentIntent?branchOfficeId=${branchOfficeId}&amount=${amount}&currencyCode=${currencyCode}&bookingId=${bookingId}&customerId=${customerId}&customerName=${customerName}&customerEmail=${customerEmail}`,
  );
};

export const getAxleIgnitionUrlToken = async () =>
  api.get(`${apiUrl}/data/axle-ignition-url-token`);

export const setAxleAuthCode = async (guid, authCode) => {
  return await api.post(`${apiUrl}/data/axle-set-authCode?guid=${guid}&authCode=${authCode}`);
};
