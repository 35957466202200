import style from 'styled-components';

export const StyledBottomFooter = style.div`
  background-color: #2C2C2C;


  p{
    font-size: 11px !important;
    color: #888888 !important;
  }

  a{
    color: red;
    font-weight: 700;

    &:hover{
      text-decoration: none;
    }
  }
`;

export const StyledFooter = style.footer`
  background: ${props => props.bgcolor || props.theme.colors.primary};
  box-sizing: border-box;
  border-top-width: ${props => props.borderSize || '1px'};
  border-top-style: ${props => props.borderStyle || 'solid'};
  color: ${props => props.color || props.theme.font.colors.secondary};
  display: flex;
  flex-wrap: wrap;
  height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || '5%'};

  position: absolute;
  bottom: 32px;
  right: 0;
  left: 0;

  @media print {
    display: none;
  }

  .linkItem {
    color: ${props => props.color || props.theme.font.colors.secondary} !important;
  }

  .linkItem:hover{
    color: ${props => props.hover || 'inhearit'}!important ;
  }

  .companyInfo {
    width: 25%;
    @media (max-width: ${props => props.theme.screens.md}) {
      width: 100%;
      padding: 0 15px;
    }
    @media (max-width: ${props => props.theme.screens.lg}) and (min-width: ${props =>
  props.theme.screens.md}) {
      flex: 0 0 35%;
      max-width: 35%;
    }

  }

  .companyInfo h3{
    color: ${({ companyInfoTitleColor }) => companyInfoTitleColor || 'inherit'};
  }

  .companyInfo p{
    color: ${({ companyInfoTextColor }) => companyInfoTextColor || 'inherit'};
  }

  .footerSocial {
    padding: 0px;
    margin-top: 1rem;
    
    .socialItem {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background-color: ${props => props.theme.colors.bg};

      p {
        color: ${({ socialColor }) => socialColor || 'inherit'};
        margin: 0;
        
        svg path {
          fill: ${({ socialColor }) => `${socialColor} !important` || 'inherit'};
        }
      }

      &:hover {
        color: ${props => props.theme.font.colors.secondary};
        background-color: ${props => props.theme.colors.secondary};
      }
    }
  }

  .footerLists {
    width: 75%;
    display: flex;
    justify-content: space-around;

    @media print {
      display: none;
    }

    @media (max-width: ${props => props.theme.screens.md}) {
      margin-top: 30px;
    }
    @media (max-width: ${props => props.theme.screens.lg}) and (min-width: ${props =>
  props.theme.screens.md}) {
      width: 65%;
    }

    .footerLinkList {
      padding: 0 15px;
      margin-bottom: 1rem;
    }

    .footerColumn {
      flex-direction: column;
      width: 25%;

      h3{
        color: ${props => props.color || props.theme.font.colors.secondary} !important
      }

      @media (max-width: ${props => props.theme.screens.lg}) and (min-width: ${props =>
  props.theme.screens.md}) {
        width: 50%;
        &.lg-hide {
          display: none;
        }
      }

      @media (max-width: ${props => props.theme.screens.md}) {
        &.md-hide {
          display: none;
        }

        width: 100%;
        flex-direction: row;
      }
    }
  }

`;
