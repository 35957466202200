import React from 'react';
import { Title, Paragraph, ModelDetail, NumberDisplay } from 'components/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { StyledDetails } from './BookingDetails.styled';
import { tt } from 'utils/translationHelper';
import Moment from 'moment';
import { fetchCategories } from 'actions';
import { getPriceWithCommission } from 'utils/dataHelper';
import { sumBy } from 'lodash';

const PriceComponent = ({ name, price }) => {
  return (
    <li>
      <i className="fas fa-plus" /> <span className="item-name">{name} </span>{' '}
      <span className="item-price">
        <NumberDisplay value={price} />
      </span>
    </li>
  );
};

class BookingDetailSection extends React.Component {
  goBack = () => {
    this.props.history.goBack();
  };

  async componentWillMount() {
    const { categories, fetchCategories, i18n } = this.props;
    if (!categories) await fetchCategories(i18n.language);
  }

  showBookingLenght = booking => {
    const { t, diasDeAlquiler } = this.props;
    const bookingLength = Number.isInteger(booking.totalDays)
      ? booking.totalDays
      : booking.totalDaysString;

    return diasDeAlquiler
      ? `Por ${bookingLength} ${diasDeAlquiler}`
      : t('forDaysOfRent', { totalDays: bookingLength });
  };

  getModel = () => {
    const {
      bookings: { confirmation },
      settings: { featureFlags },
      categories,
    } = this.props;
    let model;

    if (featureFlags.availabilityGroup !== 'category') {
      model = confirmation !== null && confirmation.car !== null ? confirmation.car.model : null;
    } else {
      if (!categories) return null;
      const bookingCategory = confirmation.category;
      const categoryFound = categories.find(c => c.id === bookingCategory.id);
      if (!categoryFound) return null;
      model = categoryFound.models.find(model => model.id === bookingCategory.principalModelId);
    }

    return model;
  };

  getPriceItemsContent = () => {
    const {
      bookings: { confirmation },
      settings: { configurations, paymentConfiguration },
      t,
    } = this.props;
    const booking = confirmation !== null ? confirmation : null;

    const getDescription = item => {
      if (item.isBookingPrice && configurations.openRate) {
        return (
          <>
            {t('openRatePriceItem', { days: item.quantity })}
            (<NumberDisplay value={item.unitPrice} /> {t('xDay')})
          </>
        );
      }
      return item.description;
    };

    const customerPriceItems = booking?.priceItems?.filter(i => i.payer === 0);

    if (paymentConfiguration && paymentConfiguration.commissionPercentage > 0) {
      const bookingPriceItems = customerPriceItems.filter(i => i.isBookingPrice);
      const extraPriceItems = customerPriceItems.filter(i => !i.isBookingPrice);

      const commissionExtra = (paymentConfiguration.commissionPercentage * booking.price) / 100;

      const bookingPriceItemsAmount = sumBy(bookingPriceItems, 'price') + commissionExtra;

      return (
        <>
          <PriceComponent
            name={`${t('alquilerPor')} ${booking.totalDaysString}`}
            price={bookingPriceItemsAmount}
          />
          {extraPriceItems.map((item, i) => {
            return <PriceComponent key={i} name={getDescription(item)} price={item.price} />;
          })}
        </>
      );
    }

    return customerPriceItems.map((item, i) => {
      return <PriceComponent key={i} name={getDescription(item)} price={item.price} />;
    });
  };

  render() {
    const {
      bookings: { confirmation },
      settings: { featureFlags, paymentConfiguration },
      t,
      title,
      subtitle,
      prefixForNumeric,
      installment,
      franchiseText,
      detailsLegend,
      bookingsConfiguration,
    } = this.props;

    const booking = confirmation !== null ? confirmation : null;
    const model = this.getModel();
    if (!model) return null;

    const showDeposit = featureFlags.showDeposit === undefined ? true : featureFlags.showDeposit;

    const kmText =
      booking.ilimitedKm === true
        ? t(
            bookingsConfiguration?.distanceUnit == 'Kilometers'
              ? 'KmIlimitados'
              : 'MillasIlimitados',
          )
        : t(bookingsConfiguration?.distanceUnit == 'Kilometers' ? 'KmDiarios' : 'MillasDiarios', {
            km: booking.maxAllowedDistanceByDay,
          });

    return (
      <StyledDetails className="col-md-3" priceBackground={this.props.priceBackground}>
        <div className="checkout-details">
          <div className="row">
            <div className="col-md-12 title-col1">
              <Title
                type="h2"
                text={title}
                italic={false}
                weight="700"
                fontSize={20}
                className="mt-1"
              />
              <Paragraph>{tt(subtitle, t)}</Paragraph>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="rent-details">
                <div className="car-item">
                  <div className="car-details">
                    <h2 className="car-name mt-1">
                      {model.brand.name} {model.name}
                    </h2>
                    <Paragraph className="car-category mb-0">
                      {t('categoria')} <span>{(model.category || booking.category).name}</span>
                      <div className="d-inline-block">
                        <span className="mr-2 ml-2">|</span>
                        <span className="tachometer text-capitalize">
                          <i className="fas fa-tachometer-alt mr-1"></i>
                          {kmText}
                        </span>
                      </div>
                    </Paragraph>
                    {showDeposit && (
                      <p className="franchise mb-3">
                        <i
                          className="fas fa-info-circle"
                          data-toggle="tooltip"
                          title=""
                          data-original-title={t('franchiseHelpText')}
                        />
                        <strong>{t(franchiseText) || t('franquicia')} </strong>
                        <span>
                          <NumberDisplay value={booking.franchise} />
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="car-image text-center mb-3">
                    <img className="img-fluid" src={model.imagePath} alt={model.name} />
                  </div>
                  <ModelDetail model={model} prefixForNumeric={prefixForNumeric} />
                </div>
                <hr />
                <div className="pickup-details">
                  <Title
                    type="h4"
                    text={t('delivery')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <p>
                    {booking.deliveryPlace.name} -
                    <span className="date">
                      {' '}
                      {Moment(confirmation.fromDate).format('DD-MM-YYYY HH:mm')} hs
                    </span>
                  </p>
                  <Title
                    type="h4"
                    text={t('return')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <p>
                    {booking.returnPlace.name} -
                    <span className="date">
                      {' '}
                      {Moment(confirmation.toDate).format('DD-MM-YYYY HH:mm')} hs
                    </span>
                  </p>
                </div>
                <hr />
                <div className="payment-details">
                  <Title
                    type="h4"
                    className="mb-3"
                    text={t('paymentDetail')}
                    weight="700"
                    fontSize={14}
                    italic={false}
                    noMedia
                  />
                  <ul className="list-unstyled">{this.getPriceItemsContent()}</ul>
                </div>
              </div>
              <div className="payment mt-3 mb-3">
                <div className="row text-light">
                  {installment ? (
                    <>
                      <div className="col-md-6">
                        <Title type="h4" fontSize="16" className="text-light" text={t('booking')} />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="">
                          <NumberDisplay
                            value={getPriceWithCommission(booking.price, paymentConfiguration)}
                          />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <Title
                          type="h4"
                          fontSize="16"
                          className="text-light"
                          text={t('totalFinancing')}
                        />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="">
                          <NumberDisplay
                            value={
                              installment.total_amount -
                              getPriceWithCommission(booking.price, paymentConfiguration)
                            }
                          />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <Title type="h3" className="total-name text-light" text={t('totalToPay')} />
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="total-price">
                          <NumberDisplay value={installment.total_amount} />
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        <Title type="h3" className="total-name text-light" text={t('totalToPay')} />
                        <p className="days">{this.showBookingLenght(booking)}</p>
                      </div>
                      <div className="col-md-6 text-right">
                        <p className="total-price">
                          <NumberDisplay
                            value={getPriceWithCommission(
                              Math.abs(booking?.customerBalance || booking?.balance),
                              paymentConfiguration,
                            )}
                          />
                        </p>
                        <p className="diario">
                          {t('dailyPrice')}{' '}
                          <span>
                            <NumberDisplay
                              value={getPriceWithCommission(
                                booking.averageDayPrice,
                                paymentConfiguration,
                              )}
                            />
                          </span>
                        </p>
                      </div>
                    </>
                  )}
                  {detailsLegend && (
                    <div className="col-md-12">
                      <Paragraph className="text-light">{tt(detailsLegend, t)}</Paragraph>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDetails>
    );
  }
}

const mapStateToProps = ({ bookings, siteConfiguration, global }) => ({
  bookings: bookings,
  installment: bookings.installment,
  settings: siteConfiguration.settings,
  categories: global.listCategories,
  bookingsConfiguration: bookings.configuration,
});
export default connect(mapStateToProps, { fetchCategories })(
  withRouter(withTranslation()(BookingDetailSection)),
);
