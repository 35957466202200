import {
  SEARCH_CARS_SET_PARAMS,
  SEARCH_CARS_SET_CARS,
  SEARCH_CARS_SET_CHECKOUT,
} from 'utils/types';
import moment from 'moment';
import { fetchCars, fetchPrice } from 'services/api';

export const setSearchCarParams = params => {
  return {
    type: SEARCH_CARS_SET_PARAMS,
    payload: params,
  };
};

export const fetchBookeableCars = (
  {
    dateFrom,
    hourFrom,
    pickUpId,
    pickUpEndpoint,
    dateTo,
    hourTo,
    dropOffId,
    dropOffEndpoint,
    onlyFullAvailability,
    promotions,
    driverAge,
    commercialAgreementCode,
  },
  language = '',
  ilimitedKm,
  showFinalPrice = false,
  globalId,
) => async dispatch => {
  try {
    const [hoursFrom, minutesFrom] = hourFrom.split(':');
    const [hoursTo, minutesTo] = hourTo.split(':');
    const from = dateFrom._isAMomentObject
      ? `${dateFrom} ${hourFrom}`
      : moment(dateFrom, 'DD-MM-YYYY')
          .set({ hour: hoursFrom, minutes: minutesFrom })
          .format('YYYY/MM/DD HH:mm');
    const to = dateTo._isAMomentObject
      ? `${dateTo} ${hourTo}`
      : moment(dateTo, 'DD-MM-YYYY')
          .set({ hour: hoursTo, minutes: minutesTo })
          .format('YYYY/MM/DD HH:mm');

    dispatch({
      type: SEARCH_CARS_SET_CARS,
      payload: { cars: [], loading: true, error: null },
    });
    const { data } = await fetchCars(
      from,
      to,
      pickUpId,
      pickUpEndpoint,
      dropOffId || pickUpId,
      dropOffEndpoint || pickUpEndpoint,
      language,
      ilimitedKm,
      showFinalPrice,
      onlyFullAvailability,
      promotions,
      globalId,
      driverAge,
      commercialAgreementCode,
    );

    return dispatch({
      type: SEARCH_CARS_SET_CARS,
      payload: { cars: data, loading: false, error: null },
    });
  } catch (err) {
    return dispatch({
      type: SEARCH_CARS_SET_CARS,
      payload: {
        cars: [],
        loading: false,
        error: err.response && err.response.data ? err.response.data : 'searchError',
      },
    });
  }
};

export const setCheckout = checkout => ({
  type: SEARCH_CARS_SET_CHECKOUT,
  payload: checkout,
});

export const fetchPrices = (
  language = '',
  additionals,
  params,
  booking,
  refreshStore = false,
  onlinePayment,
  globalId,
) => async dispatch => {
  try {
    const {
      dateFrom,
      hourFrom,
      dateTo,
      hourTo,
      pickUpId,
      pickUpEndpoint,
      dropOffId,
      ilimitedKm,
      driverAge,
      commercialAgreementCode,
    } = params;

    if (refreshStore) {
      dispatch({
        type: SEARCH_CARS_SET_CHECKOUT,
        payload: {
          booking: null,
          isLoading: true,
          onlinePayment: onlinePayment,
        },
      });
    }

    let { data } = await fetchPrice(
      language,
      additionals,
      `${moment(dateFrom, 'DD-MM-YYYY').format('YYYY/MM/DD')} ${hourFrom}`,
      `${moment(dateTo, 'DD-MM-YYYY').format('YYYY/MM/DD')} ${hourTo}`,
      pickUpId,
      pickUpEndpoint,
      dropOffId,
      booking.car.model.id,
      ilimitedKm,
      booking.promotion,
      globalId,
      driverAge,
      commercialAgreementCode,
    );

    // booking came marked as onlyQuote, we want to keep this flag after a fetchPrice
    if (booking.onlyQuote) data['onlyQuote'] = true;

    return dispatch({
      type: SEARCH_CARS_SET_CHECKOUT,
      payload: {
        booking: data,
        isLoading: false,
        onlinePayment: onlinePayment,
      },
    });
  } catch (error) {
    return dispatch({
      type: SEARCH_CARS_SET_CHECKOUT,
      payload: {
        booking: null,
        isLoading: false,
        isError: true,
        errorMessage: error.response.data.message,
        onlinePayment: onlinePayment,
      },
    });
  }
};
