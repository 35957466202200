import {
  LOG_IN,
  LOG_OUT,
  GET_USER_BOOKINGS,
  GET_USER_INFRACTIONS,
  UPDATE_PROFILE,
  RESET_PASSWORD,
} from '../utils/types';

const INITIAL_STATE = {
  isAuthenticated: false,
  profile: null,
  userBookings: {},
  userInfractions: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOG_IN: {
      return {
        ...state,
        isAuthenticated: true,
        profile: action.payload,
      };
    }

    case LOG_OUT: {
      return {
        ...state,
        isAuthenticated: false,
        profile: null,
      };
    }

    case GET_USER_BOOKINGS: {
      return {
        ...state,
        userBookings: action.payload,
      };
    }

    case GET_USER_INFRACTIONS: {
      return {
        ...state,
        userInfractions: action.payload,
      };
    }

    case UPDATE_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        isAuthenticated: false,
        profile: null,
      };
    }

    default:
      return state;
  }
};
